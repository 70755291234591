.inbox-messages-reply-header-predefined-messages {
  .inbox-messages-reply-header-predefined-messages-tag {
    font-size: 12px;
    color: #007dff;
    border: 1px solid #007dff;
    border-radius: 4px;
    display: inline-block;
    padding: 3px 7px;
    cursor: pointer;
    margin-right: 5px;
  }
}
