.fancy-profile-job {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.13);
  background-color: #f8f8f8;
  min-height: 420px;

  .fancy-profile-job-profile {
    background: #fff;
  }

  .fancy-profile-job-item {
    border-bottom: 1px solid #b5b5b5;
  }

  .fancy-profile-job-profile-item {
    display: grid;
    grid-template-columns: 24.5% auto;
    grid-gap: 33px;
    padding-top: 22px;
  }

  img, .default-pic-frame {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    border: 1px solid #efefef;
  }

  .default-image-component {
    display: grid;
    justify-content: end;
  }

  .title {
    font-size: 24px;
    color: #000000;
    font-weight: normal;
  }

  .sub-title {
    font-size: 14px;
    margin-top: 6px;
  }

  .info-data {
    font-size: 14px;
    margin-top: 13px;
    margin-bottom: 23px;
    display: grid;
    grid-template-columns: 8% auto;
    justify-items: center;
    align-items: center;
    grid-gap: 13px;
    
    .cont{
      display: grid;
      justify-self: start;
    }
  }

  .info-data-big {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 8% auto;
    justify-items: center;
    align-items: center;
    grid-gap: 13px;

    .cont{
      display: grid;
      justify-self: start;
    }
  }

  .icons {
    margin-top: 6px;

    .social-links {
      display: grid;
      grid-template-columns: 20px 20px 20px;
    }
  }
  
  .sub-sub-title{
    font-size: 12px;
    color: #979797;
    margin-top: 6px;
  }
  
  .items{
    margin-top: 16px;
    margin-bottom: 23px;
    font-size: 14px;
    color: #000000;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    
    .val{
      font-size: 14px;
    }
    
    .title{
      font-size: 14px;
      font-weight: bold;
    }
  }
}
