.styled-radio-buttons {
  margin-top: 23px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

  button.styled-radio-button {
    color: var(--color-18);
    height: 36px;
    line-height: 22px;
    border: 1px solid var(--color-16);
    cursor: pointer;
    border-right: none;
    background: var(--color-8);
    font-size: var(--size-2);
    padding: 0;
    outline: none;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid var(--color-16);
    }

    &:hover, &.active {
      background-color: var(--color-2);
      color: var(--color-8);
      font-weight: bold;
    }
  }
}
