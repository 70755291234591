.page-not-found {
  margin-top: 169px;

  h1 {
    color: var(--color-1);
    font-size: var(--size-8);
    margin-top: 15px;
  }

  p {
    color: var(--color-1);
    font-size: var(--size-6);
    text-align: center;
  }
}
