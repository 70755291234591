.log-timeline {
  position: relative;

  .timeline-item {
    position: relative;
    margin-left: 4em;
    padding-bottom: 2em;

    &:before {
      content: "";
      background-color: #dbdbdb;
      display: block;
      width: .1em;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .timeline-marker {
      position: absolute;
      background: #dbdbdb;
      border: .1em solid #dbdbdb;
      border-radius: 100%;
      content: "";
      display: block;
      height: .8em;
      left: -0.35em;
      top: 1.9rem;
      width: .8em;
    }

    .timeline-content {
      padding: 2em 0 0 2em;

      p {
        word-break: break-all;
      }
    }

    .timeline-heading {
      display: block;
      font-size: 11px;
      letter-spacing: 1px;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
    }


    .log-author {
      font-size: 13px;
      margin-top: 8px;
      color: var(--button-color);
    }


  }

  .timeline-header {
    width: 8em;
    min-width: 4em;
    max-width: 12em;
    word-wrap: normal;
    text-align: center;
    display: flex;
    justify-content: center;

    .log-tag {
      align-items: center;
      border-radius: 4px;
      display: inline-flex;
      font-size: 0.85rem;
      height: 1.5em;
      justify-content: center;
      line-height: 1.5;
      padding-left: 0.45em;
      padding-right: 0.45em;
      white-space: nowrap;
      background-color: #00d1b2;
      color: #fff;
      z-index: 1;
      cursor: auto;
    }
  }
}
