.add-to-matches {

  h2 {
    font-size: 22px;
    color: #000;
    margin-bottom: 31px;
  }

  label {
    font-size: 16px;
  }

  .send-cv-body {
    max-height: 170px;
    overflow-y: auto;
  }

  .last {
    margin-bottom: 90px;

    .err-message {
      color: var(--input-invalid-color);
    }
  }

  .add-to-matches-job-multi-select {
    margin-bottom: 21px;
  }

  footer {
    position: absolute;
    bottom: 46px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: left;
    width: 740px;
  }

  .black, .bk-less {
    justify-self: right;
    width: fit-content;
    background: #e6e6e6;
  }
  
  .add-to-matches-error-list-wrapper{
    .add-to-matches-error-list-item{
      color: #f75245;
    }
  }

  .editor-container {
    position: relative;
    .rdw-editor-wrapper {
      margin: 10px 0;
    }
  
    .rdw-editor-main {
      min-height: 110px;
    }
  }

  .candidates-footer {
    .disabled {
      pointer-events: none;
    }
  }
}
