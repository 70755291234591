.dashboard-activities-activities-wrapper {

    .title {
        margin-top: 25px;
        margin-left: 25px;    
    }

    .content {

        margin: 49px 68px 0 55px;
        position: relative;

        .lds-dual-ring {
            transform: translateY(120%);
        }

        .top1, .bottom {
            height: 100%;
            display: grid;
            grid-template-columns: 112px 69px 94px 81px 88px 84px 88px;
            justify-content: center;
        }

        .bottom {
            margin-top: 58px;
        }

    }

    .more-menu {
        position: relative;
        cursor: pointer;

        .action-item-more-menu {
            position: absolute;
            top: 0;
            right: 25px;
        }
    }

}