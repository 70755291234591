.note-container {
    .header-with-open-arrow {
        grid-template-columns: 24px 230px auto;
    }
    .header-without-open-arrow {
        grid-template-columns: 24px auto 15px;
    }
    .header {
        position: relative;
        display: grid;
        align-items: center;

        .notes-text {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.57;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        .list-icon-container {
            position: relative;
            top: 2px;
            right: 3px;
            
            svg {
                width: 24px;
            }
        }
        .edit-icon-container {
            cursor: pointer;
        }
        .arrow-down, .arrow-up {
            position: relative;
            top: 10px;
            cursor: pointer;
            height: 100%;

            &:after,
            &:before {
              border-right: 1px solid;
              content: "";
              display: block;
              height: 8px;
              margin-top: -4px;
              position: absolute;
              transform: rotate(315deg);
              left: 10px;
              top: 20%;
              width: 0;
            }
      
            &:after {
              margin-top: -4px;
              transform: rotate(-315deg);
              left: 15px;
            }
  
        }
        .arrow-up {
            &:before, &:after {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
              }
        }
    }
    .body p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
    .footer {
        margin-top: 14px;
        display: grid;
        grid-template-columns: 36px auto;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #979797;  

        .default-image-component {
            height: 28px;
        }

        .default-pic-frame, img {
            border-radius: 50%;
            font-size: 14px;
            width: 28px;
            height: 28px;
        }
    }
}