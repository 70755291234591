@import '../../../styles/mixins.scss';

.search {
  position: relative;
  margin: 0 0;


  .search-area {
    position: relative;
    clear: both;

    input {
      font-weight: 600;
      width: 0;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      border: 0;
      float: right;
      height: 31px;
      transition: width 0.3s, padding-left 0.3s linear;

      &::placeholder {
        color: var(--color-16);
      }
    }

    & > svg {
      position: absolute;
      top: 3px;
      left: 91%;
      width: 20px;
      height: 20px;
      transition: left 0.2s linear;

      path {
        fill: #fff;
      }
    }

    &.available {
      & > svg {
        left: 3%;
        transition: left 0.2s linear;
      }

      input {
        padding-left: 70px;
        width: 100%;
        color: #fff;
        visibility: visible;
        opacity: 1;
        border: solid 1px #fff;
        transition: width 0.3s, padding-left 0.3s linear;
        background: transparent;

        &::placeholder {
          color: #fff;
        }
      }

      &.search-all > input { 
        padding-left: 48px; 
      }

      &.search-all > button.search-type-dropdown {
        padding-left: 10px;
      }
    }

    &.text-right {
      input {
        padding-right: 70px;
        padding-left: 20px;
      }
    }

    button.search-type-dropdown {
      padding-left: 16px;
      min-width: 48px;
      min-height: 36px;
      border-radius: 20px;

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      & > .bp3-icon {
        color: #404040;
        transition: transform 150ms ease-in-out;
        transform: rotate(0);
      }

      &.bp4-popover2-open > .bp3-icon {
        transform: rotate(180deg);
      }
      
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
    .align-right {
      right: 0;
      left: unset !important;
      color: #6c6c6c;
    }
  }

  .search-results {
    height: fit-content;
    z-index: 2;
    position: absolute;
    background: var(--color-8);
    margin-top: 5px;
    width: 329px;
    top: 40px;
  }

  .result-item {

    &:last-child {
      &:hover {
        border-radius: 0 0 15px 15px;
      }      
    }
    &:hover, &.active {
      background: #F7F7F7;
    }

    .black-link {
      font-size: var(--size-4);
      color: var(--color-1);
      font-weight: 600;
      padding: 8px 14px;
      width: 100%;
      display: block;
      margin: 0;
      position: relative;

      span {
        font-size: var(--size-314);
        color: var(--color-4);
        font-weight: 300;
        margin-left: 11px;
        position: relative;

        &::before {
          content: '.';
          position: absolute;
          top: -1px;
          left: -5px;
        }
      }

      .sub-sub-label{
        position: absolute;
        font-size: 12px;
        color: #000;
        top: -10px;
        right: 0;
        width: 100%;
        text-align: center;
      }
    }

    .company-record {
      display: flex;
      justify-content: space-between;
      padding: 8px 14px;
      .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .align-right {
      right: 0 !important;
      left: unset !important;
      color: #6c6c6c;
    }
  }

  &.recruiter {
    width: 350px;
    // height: 36px;
    // border: 1px solid #efefef;
    // background: #EFEFEF;
    // border-radius: 50px;

    .search-area {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 1px;

      & > svg {
        top: 6px;
        right: 2px;

        path {
          fill: #02074f
        }
      }

      input {
        border: 1px solid #efefef;
        background: #EFEFEF;
        border-radius: 20px;
        color: #000;
        height: 36px;

        &::placeholder {
          color: #000000;
          opacity: 0.5;
          font-size: 14px;
          font-weight: 300;
        }
      }

      .button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        height: 28px;
        background-color: #00074F;
        color: #fff;
        font-family: Ploni DL 1.1 AAA;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
   
        width: 85px;
        margin: 0;
        padding: 0;
        align-content: center;
        display: grid;

        .advanced-search-arrow {
          position: absolute;
          right: 14px;
          top: 50%;
          transform: translateY(-50%);
          display: none;
        }
      }
    }

    .search-results {
      width: 339px;

      .result-item .black-link span::before {
        top: -4px;
      }

      .black-link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

    legend {
      font-size: 14px;
      color: #000000;
      opacity: 0.5;
      margin: 7px 0 0 7px;
    }

    @include responsive-until($md) {
      width: 100%;
      padding: 16px;
      background-color: #fff;

      .search-area input {
        height: 40px;
      }

      button.search-type-dropdown {
        min-height: 40px;
      }
      .align-right {
        right: 0;
        left: unset !important;
        color: #6c6c6c;
      }

      .search-results {
        overflow-y: auto;
      }
    }

    @include mobile {
      .search-area input:focus {
        background-color: #F8F8F8;
        border-color: #1781FB;
      }

      .search-area .button {
        width: 100%;
        top: 100%;
        left: 0;
        transform: translateY(14px);
        height: 40px;
        border-radius: 60px;

        .advanced-search-arrow {
          display: inline-block;
        }
      }
    }
  }
}

.search-type-popover {
  &.merge-company {
    width: 350px;
  }
  .bp3-menu {
    padding: 0;
    min-width: 240px;
  
    & > li:not(:last-child) {
      border-bottom: 1px solid #F6F6F6;
    }

    .bp3-menu-item {
      display: grid;
      column-gap: 5px;
      grid-template-areas:
      'icon text'
      'icon label';
      
      padding: 10px 16px;
      & > svg { grid-area: icon; }
      & > div { grid-area: text; }
      & > span { grid-area: label; }

      &.search-all {
        justify-content: center;
      }
    }

    & > li:first-child > a {
      border-top-right-radius: var(--menu-border-radius);
      border-top-left-radius: var(--menu-border-radius);
    }
  
    & > li:last-child > a {
      border-bottom-right-radius: var(--menu-border-radius);
      border-bottom-left-radius: var(--menu-border-radius);
    }
  }
}

