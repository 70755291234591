.emp-navigation-bar {
  display: grid;
  grid-template-columns: auto 1.8fr 2fr auto;

  a {
    margin-top: 24px;

    svg {
      margin-top: -2px;
      margin-left: 2px;
    }
  }

  .profile-pic {
    margin-top: 16px;

    img, .default-pic-frame {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 20px;
    }
  }

  .menu-container {
    padding-bottom: 21px;
    margin-left: 45px;

    .context-menu {
      top: 56px;

      .menu-item {
        border-bottom: 1px solid var(--color-9);
        padding: 2px 0 2px 15px;
        margin: 1px 0;
        cursor: pointer;
        line-height: 3;

        &:last-child {
          border-bottom: none;
        }

        &:hover {

          background: var(--color-2);

          a {
            color: var(--color-8);
          }
        }

        a {
          color: var(--color-18);
          margin: 0;
        }
      }
    }

    &.left {
      .context-menu {
        top: 55px;
        left: -4px;
        right: inherit;


        &:before {
          left: 15px;
          top: -10px;
          right: inherit;
        }
      }
    }
  }

  .navigation-menu {
    display: inline-block;
    position: relative;
    margin: 7px 20px 0 40px;
  
    a {
      color: var(--color-8);
      font-size: var(--size-5);
      font-weight: 600;
      opacity: 0.5;
      text-decoration: none;
      display: inline-block;
      margin-right: 40px;
  
      &:last-child {
        margin-right: 0;
      }
  
      &:active, &.active {
        opacity: 1;
        padding-bottom: 22px;
        border-bottom: 2px solid;
      }
  
      &:hover {
        opacity: 1;
      }
    }
  }
  
  .icon-menu {
    color: var(--color-8);
    font-size: 25px;
    //margin: 13px 7px 0 2px;
  
    > a > .fa {
      margin: 0 12px;
      cursor: pointer;
      color: var(--color-8);
      vertical-align: super;
  
      &:hover {
        color: var(--link-color-selected);
  
        .fa {
          color: var(--link-color-selected);
        }
      }
  
      .fa {
        font-size: var(--paragraph-font-size);
  
        &.red {
          color: var(--link-color-selected);
          margin-top: -15px;
          margin-left: -10px;
        }
      }
    }
  
    .menu-container {
      margin-left: 11px;
      vertical-align: sub;
  
      .context-menu {
        .menu-header {
          display: grid;
          grid-template-columns: 50% 50%;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid var(--color-9);
          padding: 4px 0 2px 14px;
          margin: 1px;
          cursor: pointer;
          line-height: 3;
        }
      }
  
    }
  }
  
  
  .menu-right {
    display: grid;
    align-items: center;
    grid-template-columns: 4.7fr 0.7fr auto;
    grid-gap: 9px;
  
    .bk-white-less {
      margin: 0 0 0 3px;
      height: 28px;
    }
  
    .invite {
      margin-left: 17px;
    }
  
    .icons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 18px;
      align-items: center;
      margin-top: 3px;
    }
  
    .chat-notification {
      margin-left: 3px;
    }
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 11px;
  
      .button {
        min-width: 69px;
      }
    }
  
    .image-cropper {
      margin-top: 16px;
  
      svg, .default-pic-frame {
        height: 40px;
        width: 40px;
      }
  
      .default-pic-frame {
        cursor: pointer;
        font-size: var(--size-6);
      }
    }
  }
 
}

