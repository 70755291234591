.content-grid {

  .content-grid-item {
    display: grid;
    grid-template-columns: 1fr 3.5fr;
    border-top: 1px solid var(--rare-bg-color-border);
    padding-left: 15px;
    padding-right: 15px;

    &:first-child {
      border-top: none;
    }
  }

  h3 {
    padding: 20px 0;
    line-height: 1.2;
    margin: 0;
    font-weight: 500;
    color: var(--paragraph-color);
    font-size: var(--paragraph-font-size);
    width: 50%;
  }

  h4 {
    padding: 17px 0;
    line-height: 1;
    margin: 0;
    font-weight: 500;
    color: var(--input-background-color);
    font-size: var(--paragraph-font-size);
  }

  p {
    padding: 17px 0;
    line-height: 1;
    margin: 0;
    color: var(--paragraph-color-secondary);

    &.long-text {
      line-height: 1.6;
    }
  }
}