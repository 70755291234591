input {
  border-radius: 4px;
  background: var(--color-8);
  border: solid 1px var(--color-16);
  height: 37px;
  box-sizing: border-box;
  display: block;
  padding: 0 17px;
  width: 100%;

  &[type=checkbox] {
    width: 20px;
    height: 20px;
  }

  &[type='text'] {

  }

  &[type='password'] {

  }

  &[type='number'] {

  }

  &[type='date'] {

  }

  &[type='email'] {

  }

  &[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  &:focus {
    border-color: var(--color-5);
    outline: 0;
  }

  &:disabled {
    border-color: var(--color-17);
    color: var(--color-17);
    outline: 0;
    background: transparent;
  }

  &.invalid, &:invalid {
    border-color: var(--color-7);
  }
}

select {
  border-radius: 4px;
  background: var(--color-8);
  border: solid 1px var(--color-16);
  height: 36px;
  box-sizing: border-box;
  display: block;
  width: 100%;

  padding-left: 5px;

  &:focus {
    border-color: var(--color-5);
    outline: 0;
  }

  &:disabled {
    border-color: var(--color-17);
    color: var(--color-17);
    outline: 0;
    background: transparent;
  }
}

textarea {
  width: 100%;
  height: fit-content;
  min-height: 100px;
  border-radius: 4px;
  background: var(--color-8);
  border: solid 1px var(--color-16);
  padding: 11px 19px;
  display: block;
  margin-top: 8px;
  line-height: 1.5;

  &:focus {
    border-color: var(--color-5);
    outline: 0;
  }

  &:disabled {
    border-color: var(--color-17);
    color: var(--color-17);
    outline: 0;
    background: transparent;
  }
}

label > * {
  margin-top: 10px;
}

label {
  font-size: var(--size-314);
  font-weight: bold;
  color: var(--color-18);
}

$color1: #f4f4f4;
$color2: #3197EE;

.radio {
  margin: 0.5rem;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      cursor: pointer;

      &:before {
        content: '';
        // background: $color1;
        background: var(--color-8);
        border-radius: 100%;
        // border: 1px solid darken($color1, 25%);
        border: 2px solid darken($color1, 25%);
        display: inline-block;
        width: 1.3em;
        height: 1.3em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 2px $color1;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.job-editor-wrapper {

  &.invalid {
    .rdw-editor-main {
      border-color: var(--color-7);
    }
  }

  .rdw-editor-main {
    min-height: 300px;
  }
}

.jobs-super-search-input-multi-item {
  .company {
    font-weight: normal;
  }

  .name {
    font-weight: bold;
  }

  &.selected {
    .company {
      font-weight: bold;
    }
  }
}

.jobs-super-search-input-tag {
  .company {
    font-weight: normal;
  }

  .selected {
    .company {
      font-weight: bold;
    }
  }
}
