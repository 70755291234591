.notifications-view {

    margin-top: 92px;
    background-color: #fff;

    .notifications-view-wrapper {
        height: 68px;
        display: grid;
        align-items: center;
        margin: 0;
        padding: 0;
      }

      .notifications-view-wrapper-top {
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: 160px 200px 200px auto;
        grid-gap: 40px;
        max-width: 1536px;
        align-items: center;

        font-size: 28px;
        line-height: 1.46;
        color: #000000;

        .notification-filter-container {
            select {
              height: 37px;
              border: 1px solid #979797;
              font-size: 16px;
              font-weight: normal;
            }
        }

        .select-box-container {
            align-self: center;
        }
      }


      .notifications-area {
        display: grid;
        grid-template-columns: 1134px auto;
        grid-column-gap: 20px;
        max-width: 1536px;
        margin: 0 auto;
        margin-top: 7px;

        .overviewWrapper {
          // max-height: 750px;
        }

        .earlier-container {
          margin-top: 64px;
        }


      }
    
}