.fancy-switch-button {
  position: relative;

  .text {
    font-size: var(--size-2);
    font-weight: normal;
    position: absolute;
    right: 62px;
    top: 5px;
    width: max-content;
  }

  .onoffswitch {
    position: relative;
    width: 53px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .onoffswitch-checkbox {
    display: none;
  }

  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 26px;
    padding: 0;
    line-height: 26px;
    border: 1px solid var(--color-10);
    border-radius: 26px;
    background-color: var(--color-8);
    transition: background-color 0.3s ease-in;
  }

  .onoffswitch-label:before {
    content: "";
    display: block;
    width: 26px;
    margin: 0;
    background: var(--color-8);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 1px solid var(--color-10);
    border-radius: 26px;
    transition: all 0.3s ease-in 0s;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: var(--color-2);
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: var(--color-2);
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0;
  }

}
