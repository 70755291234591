.notes-wrapper-class {
    border-top: 1px solid #d8d8d8;
    margin-top: 15px;
    padding: 20px 0;
}

// .location-and-status .location {
//     display: none;
// }

.location-and-status {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}