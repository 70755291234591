.layout-container {
  margin-top: 5.75rem;

  .layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;

    .title {
      text-align: left;
      font-size: 24px;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 0;
      color: var(--color-29);
    }
  }

  .layout-content {
    width: 100%;
    padding: 0 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.center-layout {
      margin: 0 auto;
    }
  }
}
