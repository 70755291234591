$common-width: 1fr;
$main-filter-width: 1.5fr;

.process-filter-bar {
  display: grid;
  grid-template-columns: $main-filter-width $main-filter-width $main-filter-width $main-filter-width $main-filter-width;
  gap: 1rem;

  .MuiPaper-root {
    width: 300px;
  }
}

.clear-filter{
  display: flex;
  justify-content: flex-end;
}
