.grid-table {
  header {
    background: #02074f;
    line-height: 2.2;
    padding-top: 5px;
    height: 40px;

    ul {
      display: grid;
      color: var(--color-18);
      font-weight: 600;
      font-size: var(--size-314);

      li {
        color: #fff;
      }

      svg {

        path {
          fill: #fff;
        }
      }
    }
  }

  .grid-body {
    background: var(--color-8);
    border: 1px solid var(--color-9);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    strong {
      color: var(--color-18);
      font-size: var(--size-4);
      word-break: break-word;
    }

    .grid-body-item {
      display: grid;
      border-bottom: 1px solid var(--color-9);
      padding-bottom: 9px;

      &:hover {
        .actions {
          display: block;
        }
      }

      .actions {
        display: none;
        position: absolute;
        right: 2px;
        bottom: 11px;
      }

      &.no-data {
        min-height: 200px;
        text-align: center;
        display: block;
        padding-top: 51px;
      }

      &:last-child {
        border-bottom: 0;
      }

      .dots {
        position: absolute;
        top: 16px;
        right: 12px;
        cursor: pointer;
      }

      .update {
        font-size: var(--size-5);
        color: var(--color-18);
        font-weight: 600;
        margin-top: 20px;

        p, div {
          font-size: var(--size-2);
          color: var(--color-18);
          font-weight: 300;
          margin-top: 2px;
        }

      }

    }
  }
}
