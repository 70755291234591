.view-highlights {
  padding: 2px 10px 2px 10px;
  color: var(--color-8);
  height: 22px;
  font-size: var(--body-font-size);
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 400;
  margin: 0;
  background-color: rgba(127, 132, 135, 0.7);
  position: absolute;
  right: 0;
  top: -8px;
  cursor: pointer;
}
