.header {
  display: grid;
  grid-template-columns: auto max-content;
  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
.create-user {
  display: grid;
  grid-template-rows: 20px 50px 50px 50px 50px 50px 40px;
  
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  height: auto;
  
  .names-container {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
  }

  .disabled {
    pointer-events: none;
  }
  .error-message {
    margin: 0px auto;
    height: 1em;
    color: --color-7;
  }
  .quill-bs-container {
    .ql-container ql-snow {
      border-style: none solid none none;
    }
    .ql-editor {
      max-height: 100px;
      &::-webkit-scrollbar {
        width: 4px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #404040;
        border-radius: 0px;
      }
    
      &::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 0px;
      }
    
    }
  }

  .create-button {
    cursor: pointer;
    margin: 0 auto;
    // margin-top: 40px;
    background: #00074F;
    border-radius: 54px;
    height: 36px;
    width: 120px;
    font-family: Ploni DL 1.1 AAA;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
  }

  .header{
    font-family: Ploni DL 1.1 AAA;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    
    color: #404040;
  }
}

