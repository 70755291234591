@import '../../../../styles/mixins.scss';

.fake-wrapper {
  display: block;
  width: 1200px;
  margin: 50px auto;
}

.send-new-message-wrap {
  .send-conversation-header {
    // display: grid;
    // grid-template-columns: 12% 38% 30% auto;
    margin: 32px 0 17px 1px;
    display: flex;
    align-items: center;
    gap: 20px;

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

  }

  .send-new-message-top {
    h2 {
      font-weight: normal;
      font-size: 22px;
      margin: 2px 0 0 0;
    }
  }

  .circle-close.modal-close {
    right: 39px;
    top: 31px;

    @include mobile {
      top: 16px;
      right: 16px;
    }
  }

  .dal-body {
    padding: 0;
  }

  .send-conversation-header-tags {
    .modern-select-list {
      width: 130px;
    }
  }

  .rdw-editor-wrapper {
    height: 511px;
  }

  footer {
    padding: 0;

    .button {
      display: block;
      margin: 15px 0;
      width: 105px;

      svg, span {
        display: inline-block;
        vertical-align: top;
      }

      span {
        vertical-align: middle;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.7;
        margin-left: 5px;
      }
    }

    .disabled-opacity-less {
      span {
        color: rgba(0, 0, 0, 0.5);
      }

      svg {
        path {
          fill: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .new-message-reply-to-static {
    font-size: 16px;
    color: #979797;
    font-weight: bold;
    margin: 12px 0;

    span {
      font-weight: normal;
    }
  }
}
