.pipeline-filter-bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.clear-filter{
  display: flex;
  justify-content: flex-end;
}
