@import '../../../styles/mixins.scss';

.bp3-popover-content {

  .bp3-menu {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 5px;

    .multi-item.suggest-item {
      .default-pic-frame, img {
        font-size: 16px;
        border-radius: 50%;
      }
    }

    .multi-item {
      font-size: var(--size-3);
      color: var(--color-18);
      line-height: 1.9;
      text-align: left;
      padding: 2px 0 2px 15px;
      cursor: pointer;
      display: flex;

      &:hover {
        background: var(--color-3);
      }

      &.active {
        background-color: var(--color-12);
      }

      &.selected {
        font-weight: bold;
      }

      &.smaller-size {
        font-size: var(--size-2);
      }

      &.edit-profile-multi-pulti-multi{
        max-width: 330px;
        text-transform: capitalize;
      }

      &.suggest-image {
        align-items: center;
        gap: 10px;
      }

      label {
        flex: 1;
        font-size: var(--size-3);
        color: var(--color-18);
        line-height: 1.9;
        text-align: left;
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .exclude {
        
        svg {
          color: red;
          height: 100%;
          align-self: center;
          margin-right: 15px;
          margin-left: 15px;
        }
      }

      .content-image {
        .default-image-component > svg {
          width: 40px;
          height: 40px;
        }
      }
    }
    .multi-content{
      span{
        margin-right: 5px;
      }

      .sub-content {
        color: #707070;
        font-size: 13px;
      }
    }

    .profile-multi-render {
      width: 405px;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
      padding: 5px 0 2px 8px;
      margin-bottom: 6px;

      @include mobile {
        width: 100%;
      }

      .company-email-render {
        width: 36px;
        height: 26px;
      }
      .profile-multi-render-img {
        .default-pic-frame, img {
          width: 36px;
          height: 36px;
          font-size: 19px;
          border-radius: 50%;
        }
      }

      .profile-multi-render-info {
        .name {
          font-weight: bold;

          .user-type {
            font-weight: 300;

            span{
              color: #979797;
              text-transform: lowercase;
            }
          }
        }

        .name, .company {
          line-height: 1.2;
          text-transform: capitalize;
        }
      }
    }
  }
}

.tag-multi-render {
  cursor: pointer;

  &.mandatory {
    color: var(--color-26);
  }

  &.excluded {
    color: var(--color-7);
  }
}

.bp3-popover-arrow {
  display: none;
}

.multiselect-wrapper {
  margin-top: 5px;
}

.multiselect-wrapper, .suggestion-wrapper {

  .invalid {
    .bp3-input {
      border: solid 1px var(--color-7);
    }
  }

  .excluded {

    .bp3-input {
      color: var(--color-7);
    }
  }

  .bp3-popover-target {
    width: 100%;

    .bp3-disabled {

      input {
        background: var(--color-3);
      }
    }
  }

  .bp3-multi-select {
    min-height: 36px;
    border-radius: 4px;
    background: var(--color-8);
    border: solid 1px var(--color-16);
    box-shadow: none;

    &.bp3-active {
      box-shadow: none;
      border-color: var(--color-5);

    }
  }

  .bp3-tag-input-values {
    margin-top: 3px;

    .missing-tags {
      color: #FF0000;
      font-weight: normal; 
    }
  }

  .bp3-tag {
    background-color: var(--color-12);
    color: var(--color-18);
    font-size: var(--size-4);
    padding: 7px 6px;
    border-radius: 4px;
  }

  .bp3-input {
    min-height: 36px;
    border-radius: 4px;
    background: var(--color-8);
    border: solid 1px var(--color-16);
    box-shadow: none;
  }

  &.custom-scroll {

    .bp3-tag-input-values {
      overflow: auto;
      max-height: 140px;
      padding-right: 5px;
      
      &::-webkit-scrollbar {
        width: 10px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: rgb(196, 196, 196);
        border-radius: 3px;
      }
    
      &::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 3px;
      }
    }
  }
}

.suggestion-wrapper {
  label{
    margin-bottom: 4px;
    display: block;
  }

  .bp3-input {
    height: 36px;

    &:focus{
      border-color: var(--color-5);
      outline: 0;
      box-shadow: none;
    }
  }

  position: relative;
  width: 100%;

  .arrow-icon {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    margin-left: 1px;
    padding-right: 0px;
    background-color: transparent;
    color: black;
    display: grid;

    &:after,
    &:before {
      border-right: 1px solid;
      content: "";
      display: block;
      height: 8px;
      margin-top: -4px;
      position: absolute;
      transform: rotate(45deg);
      right: 18px;
      top: 50%;
      width: 0;
      z-index: 1;
      pointer-events: none;
    }

    &:after {
      margin-top: -4px;
      transform: rotate(-45deg);
      right: 23px;
      z-index: 1;
    }

  }

  .button {

    padding:0;
    width: 100%;

    &.loading {

      &:after  {
        left: initial;
        right: 12px;
        border-color: var(--color-18) var(--color-18) var(--color-3) var(--color-3) !important;
    }
  }
 }
}

.new-message-super-search-input-fancy {
  .multiselect-wrapper {

    .bp3-input {
      height: auto;
    }

    .bp3-tag-input-values {
      margin-top: 0;
    }

    .bp3-input-ghost {
      margin: 0 5px 0 10px;
    }

    .bp3-tag {
      font-size: 10px;
      border-radius: 3px;
      padding: 2px 9px;
      margin: 0 0 1px 5px;
      background: #d8d8d8;
      font-weight: normal;
      color: #000;
    }
  }
}

.multi-with-cc-bcc {
  .bp3-input {
    padding-right: 70px;
  }
}

.bp3-multi-select-popover {
  .bp3-popover-content {
    padding: 8px 4px;
  }


  .bp3-menu {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #666;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
      border-radius: 3px;
    }
  }
}

.new-message-super-search-input {
  .bp3-tag {
    font-weight: bold;
  }
}

.bp3-input-group{
  input{
    text-transform: capitalize;
  }
  .bp3-input {
    white-space: nowrap; 
    padding-right: 30px;
    // width: 90% !important; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
}
