.form {
    .reminder-infor {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;
        .left-infor {
            width: 45%;
            margin-right: 50px;
            div {
                margin-bottom: 15px;
            }
            .suggestion-wrapper {
                label {
                    margin-bottom: 15px;
                }
                input {
                    height: 40px;
                    border-radius: 10px;
                }
            }
        };
        .right-infor {
            width: 45%;
            div {
                margin-bottom: 10px;
            }
        }
        .infor-date {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }
    };

    .flex-column {
        flex-direction: column;
    }
 }
 .action-button {
    display: flex;
    justify-content: space-between;
}