.search-results-wrapper {
  h1 {
    font-size: var(--size-3);
    margin: 76px 0 9px 14px;
  }

  .back-button {
    margin: 50px auto;
  }
}
