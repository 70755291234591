.complex-ranger-chuck-wrapper {
    position: relative;
    z-index: 51;

    .main-input-wrapper {

        position: relative;
        cursor: pointer;

        &.open {
            border-bottom: solid 1px var(--color-1);
            padding-bottom: 2px;
        }

        .main-input {

            padding-top: 8px;
            padding-left: 6px;
            margin-left: 0px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000000;
            z-index: 51;
            cursor: pointer;    
            box-shadow: none;
            border: none;
            border-radius: 0;
            height: 34px;
            background-color: transparent;

            &.open {
                color: #979797;
            }

        }
    
        .plus-icon {
            
            position: absolute;
            top: 2px;
            right: 0;
            padding-right: 0px;
            background-color: transparent;
            color: var(--color-2);
            display: grid;
            font-size: 24px;
            font-weight: normal;
            &:after,
            &:before {
                content: "+";
                display: block;
                position: absolute;
                right: 3px;
                top: 4px;
                z-index: 1;
                pointer-events: none;
            }
            cursor: pointer;
        }
        
        .arrow-icon {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            position: absolute;
            margin-left: 1px;
            padding-right: 0px;
            background-color: transparent;
            color: black;
            display: grid;
            top: 2px;
            right: 0;
            left: 0;
    
            &:after,
            &:before {
                border-right: 1px solid;
                content: "";
                display: block;
                height: 8px;
                position: absolute;
                transform: rotate(45deg);
                right: 5px;
                top: 15px;
                width: 0;
                z-index: 1;
                pointer-events: none;
            }
    
            &:after {
                transform: rotate(-45deg);
                right: 10px;
                z-index: 1;
            }
        }
    
    } 

    .tag-render {
        display: inline-block;
        padding: 4px 9.35px;
        margin-right: 2px;
        height: 24px;
        width: fit-content;
        border-radius: 12px;
        border: solid 1px #41a3fb;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        font-size: 12px;
        color: white;
        background-color: #41a3fb;
        margin-left: 5px;
        margin-bottom: 5px;
        margin-top: 8px;
        max-width: 190px;
        cursor: initial;
        position: relative;

        &.excluded {
              background-color: var(--color-7);
              border: solid 1px var(--color-7);            
        }

        &:first-child {
            margin-top: 7px;
        }

        .change-icon, .close-icon {
          position: absolute;
          display: none;
          cursor: pointer;
        }

        .multi-render-item-label {
          top: initial;
          left: initial;
          position: relative;
          font-weight: normal;
          font-size: 12px;
          visibility: visible;  
          color: white; 
      }

        cursor: pointer;

          &:hover {
              border: solid 1px #41a3fb;
              background-color: #efefef;
              color: #efefef;
              cursor: default;

              &.excluded {
                  border: solid 1px var(--color-7); 
              }
  
              .change-icon {
                  display: inline;
                  left: 50%;
                  transform: translate(calc(-50% - 8px), 3%);

                  &.include {

                      path, circle {
                          stroke: #41a3fb;
                      }
                  }
    
              }
      
              .close-icon {
  
                  display: inline;
                  left: 50%;
                  transform: translate(calc(-50% + 8px), -5%);
                  
  
                  svg {
                      height: 16px;
                      width: 16px;
                  }
              }
  
              .multi-render-item-label {
                  visibility: hidden;
              }
          }
    }

    &.exclude {
      input {
        color: red;
      }
    }

    .ranger-chuck {
        border-radius: 4px;
        box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.09);
        background-color: #ffffff;
        height: fit-content;
        width: 100%;
        padding: 15px 20px;
        position: absolute;
        top: 41px;
        z-index: 3;

        .salary-type {
            margin-bottom: 31px;
        }
        
      }

      .input-range__label-container {
        display: none;
      }
    
      .range-inputs {
        margin-top: 17px;
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        grid-gap: 3px;
        text-align: center;
    
        .to {
          margin-top: 9px;
        }
    
        input {
          text-align: center;
          padding: 0;
          font-size: 14px;
        }
      }
    
      .button-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 38px;
    
        .add-buttons {
    
          display: flex;
          justify-content: flex-end;
    
          .exclude {
            margin-right: 20px;
            color: red;
          }
        }
      }
    
  }