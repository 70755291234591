.search-results-filter-wrapper {

    width: 100%;
    padding: 0 20px;

    &.not-ready {
        pointer-events: none;
        opacity: 0.5;
    }

    .complex-multi-select-wrapper {
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }
    }

    .complex-ranger-chuck-wrapper {
        margin-top: 10px;

        &.salary-range {
            z-index: 51;
        }
    }

}