button {
  border: none;
}

.button {
  cursor: pointer;
  display: inline-block;
  width: fit-content;
  padding: 10px;
  border-radius: 4px;
  color: var(--color-8);
  outline: 0;
  font-size: var(--size-5);
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  .fa, .far, .fas {
    margin-right: 10px;
    font-size: var(--paragraph-s-font-size);
  }

  &.gray {
    background: var(--color-4);

    &:hover {
      background-color: var(--icon-color-hover);
    }
  }

  &.red {
    background: var(--color-7);

    &:hover {
      background: rgb(221, 86, 86);
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  &.blue {
    background: var(--color-2);
    font-size: 14px;

    // &:hover {
    //   background: var(--color-15);
    //   color: var(--color-8);
    // }
  }
  &.blue2 {
    background: #00074F;
    font-size: var(--size-5);

    &:hover {
      background: var(--color-15);
      border: 1px solid var(--color-15);
      color: var(--color-8);
    }
  }
  &.black {
    background: var(--color-18);
    font-size: var(--size-5);

    &:hover {
      background: #313131;
    }
  }

  &.green {
    background: var(--icon-ok-color);
    color: var(--color-8);

    &:hover {
      background: var(--icon-ok-color-hover);
    }
  }

  &.min140 {
    min-width: 140px;
    text-align: center;
  }

  &.small {
    padding: 4px 10px;
  }

  &.square {
    width: fit-content;
    background: var(--action-text-color);

    &:hover {
      background: var(--action-text-color-selected);
    }
  }

  &.loading {
    color: transparent !important;
    pointer-events: none;
    position: relative;

    &:after {
      -webkit-animation: spinAround .5s infinite linear;
      animation: spinAround .5s infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 50%;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      border-color: transparent transparent #fff #fff !important;
    }
  }

  &.white {
    border: 1px solid transparent;
    background: #fff;
    font-size: 12px;
    font-weight: normal;
    color: #02074f;
    min-width: 79px;
    padding: 6px;

    &:hover {
      background: #e6e6e6;
    }
  }

  &.prog {
    cursor: initial;
    background: var(--bg-color) !important;
    color: #333333 !important;
  }

  &.bk-less {
    border: 1px solid var(--color-18);
    background: transparent;
    font-size: var(--size-314);
    font-weight: bold;
    color: var(--color-18);
    min-width: 79px;
    padding: 6px;

    &:hover {
      background: #e6e6e6;
    }
  }

  &.bk-less2 {
    border: 1px solid #979797;
    background: transparent;
    font-size: var(--size-314);
    font-weight: normal;
    color: var(--color-18);
    min-width: 79px;
    padding: 6px;

    &:hover {
      background: #e6e6e6;
    }
  }

  &.bk-black {
    border: 1px solid var(--color-18);
    background: var(--color-18);
    font-weight: bold;
    font-size: var(--size-314);
    color: var(--color-8);
    min-width: 79px;
    padding: 6px;

    &:hover {
      background: #313131;
    }
  }

  &.bk-white-less {
    border: 1px solid var(--color-8);
    background: transparent;
    font-weight: bold;
    font-size: var(--size-314);
    color: var(--color-8);
    min-width: 79px;
    padding: 6px;
  }

  &.bk-blue-less {
    border: 1px solid var(--color-2);
    background: transparent;
    font-weight: bold;
    font-size: var(--size-314);
    color: var(--color-2);
    min-width: 79px;
    padding: 6px;
  }

  &.bk-darkblue-less {
    border: 1px solid var(--color-21);
    background: transparent;
    font-weight: bold;
    font-size: var(--size-314);
    color: var(--color-21);
    min-width: 79px;
    padding: 6px;
  }

  &.bk-gray-less {
    border: 1px solid var(--color-20);
    background: transparent;
    font-weight: bold;
    font-size: var(--size-314);
    color: var(--color-20);
    min-width: 79px;
    padding: 6px;
  }

  &.bk-darkblue {
    background: var(--color-21);
    font-weight: bold;
    font-size: var(--size-314);
    color: var(--color-8);
    min-width: 79px;
    padding: 6px;
  }

  &.input-button {
    border-radius: 4px;
    background: var(--color-8);
    border: solid 1px var(--color-16);
    height: 36px;
    box-sizing: border-box;
    display: block;
    padding-left: 10px;
    width: 100%;
    color: var(--color-18);
    font-size: var(--size-4);
  }

  &.oval {
    border-radius: 54px;
    border: 1px solid #00074F;
    width: 113px;
  }

  &.oval-border-none {
    border-radius: 54px;
    width: 113px;
  }

  &.border-bottom-gray {
    background: none;
    color: var(--color-4);
    border-bottom: 1px solid var(--color-4);
    border-radius: 0px;
  }

  &.darkblue {
    background: var(--color-21);
  }

  &.link {
    font-size: 14px;
    color: #02074f;
    background: transparent;
    padding: 0;
  }

  &.new-gray{
    background: #d8d8d8;
  }

  &.darkblue-new{
    background: #02074f;
  }

  &.disabled-opacity-less{
    cursor: default;
  }

  &.arrow {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    margin-left: 1px;
    padding-right: 18px;
    background-color: transparent;
    color: black;

    &:after,
    &:before {
      border-right: 1px solid;
      content: "";
      display: block;
      height: 8px;
      margin-top: -4px;
      position: absolute;
      transform: rotate(315deg);
      left: 10px;
      top: 20%;
      width: 0;
    }

    &:after {
      margin-top: -4px;
      transform: rotate(-315deg);
      left: 15px;
    }

    &.close {
      &:after,
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.round-button {
  background: var(--input-background-color);
  color: var(--color-8);
  font-size: 22px;
  display: inline-block;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  position: relative;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background: var(--nav-color);
  }

  .fa {
    position: absolute;
    top: 5px;
    left: 9px;
  }
}

.group-buttons {
  margin-top: 10px;

  .button {
    margin: 0;
    border-radius: 0;
    padding: 8px 9px;

    .fa {
      font-size: var(--icon-m-font-size);
      margin: 0;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.button-selector {
  border-radius: 4px;
  overflow: hidden;
  height: fit-content;

  .tabs {
    display: grid;
  }

  .tab {
    display: inline-block;
    background: var(--table-hd-text-color);
    color: var(--color-8);
    padding: 10px 4px;
    text-align: center;
    cursor: pointer;
    border-right: solid 1px var(--input-background-color);

    &.selected, &:hover {
      background: var(--nav-color);
      color: var(--color-8);
    }
  }
}

.split-input-button {
  display: grid;
  grid-template-columns: 1fr 150px;
  margin-bottom: 20px;

  .split-button {
    background: var(--table-hd-text-color);
    color: var(--color-8);
    text-align: center;
    padding-top: 8px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.remove-button {
  grid-column-start: 2;
  justify-self: right;
  margin-bottom: 23px;

  &.gray {
    box-sizing: content-box;
    text-align: center;
    padding: 4px 20px;
    height: 16px;
    font-size: var(--body-font-size);
    cursor: pointer;
    display: inline-block;
    width: fit-content;
  }
}

.remove-button-wrapper {
  text-align: right;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
