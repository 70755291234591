.mail-action {
  background: var(--color-8);
  width: 997px;
  margin: 20px auto;
  position: relative;
  display: grid;
  grid-template-columns: 29% auto;
  height: max-content;

  &.success {
    display: block;
  }


  h2 {
    text-align: center;
    margin-bottom: 37px;

  }

  .action-success {
    text-align: center;
    text-transform: capitalize;

    h2 {
      padding-top: 28px;
    }

    a {
      font-size: 16px;
    }
  }

  .quick-details {
    margin-top: 0;

    .default-pic-frame {
      width: 50px;
      height: 50px;
      font-size: var(--size-6);
    }

    .quick-details-left-profile {

      .image-cropper {
        margin: 0 auto;
      }
    }

  }

  .action-process {
    padding: 34px 13% 0 50px;
    grid-template-rows: 1fr 3fr 1fr;

    &.terminate-process-open {
      grid-template-rows: 1fr 2fr 1fr 1fr;
    }

    &.expose-component {
      grid-template-rows: 9fr 1fr;
      height: 383px;
      display: block;
      padding: 76px 10px 10px 40px;
      width: 77%;
      margin: 106px auto 0;

      h4 {
        font-size: var(--size-5);
        font-weight: normal;
        margin-top: 5px;
      }

      footer {
        margin-top: 62px;
      }
    }


    header {

      h2 {
        color: var(--color-18);
        margin: 0 0 6px;
      }
    }

    .recruiter-side-header, .candidate-side-header {
      font-size: var(--size-4);
      font-weight: bold;
      color: var(--color-18);
    }

    label {
      font-size: var(--size-4);
      font-weight: normal;
    }

    .reason-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .radio {
      margin: 0.8rem 0 0.8rem;
    }

    .radio input[type="radio"] + .radio-label:before {
      margin-right: 0.3em;
    }

    .footer {
      align-self: center;

      .done-button {
        margin-left: 10px;
      }
    }
  }

}

.action-process {
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;

  header {

    h2 {
      font-size: var(--size-6);
      font-weight: 600;
      line-height: 1;
      margin: 0 0 7px;
      text-align: left;
    }

    h3 {
      font-size: var(--size-5);
      font-weight: 300;
    }

    p {
      padding: 0;
      margin: 7px 0;
    }

  }

  .action-process-body {
    color: var(--text-color);
  }

  .form1 {
    margin-top: 5px;

    label {
      display: block;
      padding-bottom: 7px;
    }

    textarea {
      width: 100%;
      height: 118px;
      box-sizing: border-box;
    }
  }

  .radio-button {
    input {
      margin-right: 15px;
    }
  }

  footer {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .button {
      width: 182px;
      height: fit-content;

      &.handler {
        justify-self: right;
      }
    }
  }

  .footer {
    justify-self: right;
    align-self: end;

    .button {
      width: max-content;
      min-width: 80px;
      height: 40px;
    }

    .red {
      margin-left: 26px;
    }
  }


  &.terminate-process {

    .action-process-body {


      @media (max-width: 550px) {
        display: block;
      }

      label {
        display: block;
        padding: 4px 0;

        .customCheckbox {
          padding-right: 17px;

          input {
            vertical-align: text-top;
          }
        }
      }
    }
  }

  &.pause-process {
    h2 {
      color: var(--paragraph-color-secondary);
    }

    .action-process-body {
      margin-top: 8px;

      @media (max-width: 550px) {
        display: block;
      }

      label {
        display: block;
        padding: 7px 0;

        .customCheckbox {
          padding-right: 17px;

          input {
            vertical-align: text-top;
          }
        }
      }
    }
  }

  &.expose-component {
    grid-template-rows: 8fr 1fr;

    h3 {
      color: var(--color-18);
      font-size: var(--size-6);
    }

    h4 {
      color: var(--color-18);
      font-size: var(--size-4);
      line-height: 1;
      margin: 0;
      text-align: left;
    }

    footer {
      align-items: end;
    }
  }
}
