.dashboard-activities-wrapper {
    
    display: grid;
    grid-template-columns: auto 352px;

    h1 {
        font-size: var(--size-5);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1em;
        letter-spacing: normal;
        color: var(--color-10);
    }


    .dashboard-wrapper-top {

        display: grid;
        grid-template-columns: repeat(4, 271px);
        column-gap: 25px;
        height: 394px;

        .all, .conversations, .today-jobs, .jobs  {
            background-color: white;
        }

    }

    .dashboard-wrapper-bottom {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 739px 395px;
        column-gap: 25px;
        height: 394px;

        .activities {
            background-color: white;
        }

        .people {
            background-color: white;
        }
    }

    .calender {
        height: 812px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--size-8);
    }
}