.dashboard-activities-all-wrapper {

    .more-menu {
        position: relative;

        svg {
            position: absolute;
            top: 0;
            right: 25px;
        }
    }

    .column {
        margin-top: 25px;
        display: grid;
        grid-template-rows: repeat(2, 182.5px);

        .candidate-title {
            margin-left: 25px;    
            line-height: 1em;
        }

        .line1 {
            // padding-left: 40px; 
            margin-top: 38px;
            display: grid;
            grid-template-columns: auto auto auto;
            // column-gap: 25px;
        }
    
        .bottom {
            border-top: solid 1px var(--color-12);
            margin-left: 25px; 
            margin-right: 25px; 

            .job-title {
                margin-top: 27px;
                line-height: 1em;
            }
        
            .line2 {
                margin-top: 21px;
                padding-left: 5px; 
                display: grid;
                grid-template-columns: 92px auto;
                column-gap: 45px;
            }
        }
    
    }

}
