.fit-component {
  position: absolute;
  top: 11px;
  left: 4px;

  .fit-number {
    position: absolute;
    top: 13px;
    left: 8px;
    width: 41px;
    text-align: center;
  }

  .status {
    margin-top: -7px;
    font-size: 10px;
    color: #fff;
    background: #02074f;
    width: fit-content;
    margin-left: 6px;
    border-radius: 4px;
    padding: 2px 7px;
  }

  svg {
    transform: rotate(-270deg);
  }

  .circle_animation {
    stroke-dasharray: 206;
    stroke-dashoffset: 206;
  }

  .circle_animation {
    -webkit-animation: html 1s ease-out forwards;
    animation: html 1s ease-out forwards;
  }

  @keyframes html {
    to {
      stroke-dashoffset: var(--stroke-size);
    }
  }
}
