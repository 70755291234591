.search-results-list-wrapper {

    .lds-dual-ring {
        right: 15%;
        left: 15%;
        bottom: 5%;
        top: initial;

    }

    .search-results-list {
        position: relative;

        .search-item-main{
            border-bottom: solid 1px #d8d8d8;
        }
    }

    .talent-cv {
        width: 720px;
        position: fixed;
        display: block;
        border: 1px solid #d8d8d8;
        padding: 10px;
        top: 230px;
        margin-left: 100px;
        background-color: #fff;
        z-index: 9999;
        height: 400px;
        overflow-y: auto;
    }

    


}
