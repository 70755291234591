.search-result-job-item-wrapper {

    padding-top: 18px;
    padding-bottom: 22px;
    cursor: pointer;

    &:first-child {
        padding-top: 17px;

        .customCheckbox {
            margin-top: 12px;
        }

        .profile-display {
            margin-top: 1px;
        }
    }


    &.selected {
        background-color: #f4faff;
    }

    .activity-part {
        opacity: 0;
    }

    &:hover .activity-part {
        opacity: 1;
    }

    h1 {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #404040;
        margin: 0;
    }

    a {
        label {
            cursor: pointer;
        }
    }

    .content-wrapper {
        display: flex;
        margin-left: 17px;

        .customCheckbox {
            margin-top: 18px;
    
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
            }
    
            input[type="checkbox"]:before {
                width: 10px;
                height: 5px;
                top: 6px;
            }
    
            input[type="checkbox"]:after {
                width: 18px;
                height: 18px;
            }
        }

        .content {
            margin-left: 19px;
            position: relative;
            width: 100%;

            .job-display {
    
                img {
                    width: 60px;
                    height: 60px;
                    // border-radius: 50%;
                }
    
                .default-pic-frame {
                    width: 60px;
                    height: 60px;
                    font-size: var(--size-4);
                }
    
                .default-image-component {
    
                    &:hover + .interview-tooltip {
                        display: block !important;
                    }
                }
            }
    
            .inner-row {
                width: 100%;
                margin-right: 7px;
                margin-left: 26px;
    
                .row1 {
                    display: grid;
                    grid-template-columns: 83.5% auto;
        
                    .left-part {
                        display: flex;
                        position: relative;
        
                        .title {
        
                        }
                    }
        
                    .right-part {
                        display: grid;
                        grid-template-columns: 89% 11%;
        
                        .activity-part {
                            display: grid;
                            grid-template-columns: 35px 34px 34px auto;
                            transition: all 0.1s ease-out;
                            text-align: center;
        
                            .remove {
                                margin-top: -1px;                     
                            }
                        }
        
                        svg {
                            margin-top: 5.5px;
        
                            #a {
                                fill: black;
                            }
        
                            #b {
                                stroke: black;
                            }
                        }
        
                        .add-to-matches {
                            svg {
                                margin-top: 6px;
                            }
                        }
        
                        .more-menu {
                            position: relative;
                            cursor: pointer;
                            margin-top: 2px;
        
                            .action-item-more-menu {
                                position: absolute;
                                top: -10px;
                                right: 0px;
        
                                .hidden-menu {
                                    .hidden-menu-item {
                                        margin: 0;
                                        padding-left: 5px;
                                        padding-top: 9.5px;
                                        padding-bottom: 9.5px;
        
                                        svg {
                                            margin-top: 0;
                                            justify-self: center;
                                        }
                                    }
        
                                    .add-to-job {
                                        svg {
                                            width: 21px;
                                            height: 21px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
        
                .row2 {
                    margin-top: 4px;
                    display: grid;
                    grid-template-columns: 23.5% 21% 20% 14% auto;
        
                    a {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    label {
        
                        font-size: 16px;
                        font-weight: normal;
                        color: #404040;
                        position: relative;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-right: 45px;
            
                        &:after{
                            content:"\A";
                            position: absolute;
                            width: 4px;
                            height: 4px;
                            top: 7px;
                            right: 30px;
                            border-radius:50%;
                            background-color: #404040;
                        }
    
                        &:last-child:after {
                            display: none;
                        }
                        
                    }

                    .status-bar {
                        justify-content: flex-start;
                        line-height: 1em;

                        .status  {
                            &::before {
                                font-size: 14px;
                            }
                        }

                        .status-bar-text {
                            margin-top: -1px;
                            font-size: 16px;
                            align-self: center;
                            color: #404040;
                            line-height: 1em;
                        }
                    }
        
                    .company {
                        font-weight: 600;
                    }
        
                }
            }

            .more-details {

                margin-top: 52px;
                display: grid;
                grid-template-columns: 31.8% 44.8% auto;

                label {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #404040
                }
                
                .more-details-title {
                    font-weight: 600;
                }

                a {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #404040
                }

                .more-details-item {

                    display: grid;
                    grid-template-columns: 85px auto;
                    grid-template-rows: 29px auto;
                }

                .column-two {

                    .skills {
                        margin-top: 13px;
                        margin-right: 40px;
            
                        .skill-tag {
                            display: inline-block;
                            padding: 4px 9.35px;
                            margin-right: 7px;
                            margin-bottom: 10px;
                            height: 24px;
                            width: fit-content;
                            border-radius: 12px;
                            border: solid 1px #41a3fb;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.42;
                            font-size: 12px;
                            background-color: white;
                            color: #41a3fb;
            
                            &.mandatory {
                                color: white;
                                background-color: #41a3fb;
                            }
                        }
                    }
                }

                .column-three {
                    display: grid;
                    grid-template-rows: 29px auto;
                }
            }

            .contact-person {
                display: flex;
                margin-top: 23px;

                .hr-manager {

                    flex: 0.985;

                    .coordinator-data {
                        display: grid;
                        grid-template-columns: 5.4% 45.5% auto;

                        .default-pic-frame {
                            height: 40px;
                            width: 40px;
                        }
        
                        legend {
                            font-size: 14px;
                            font-weight: 600;
                            color: #404040;
                        }
        
                        p {
                            font-size: 14px;
                            font-weight: normal;
                            color: #404040;
                        }
        
                        img {
                            height: 40px;
                            width: 40px;
                        }

                        a {
                            margin-top: -2px;
                        }
                    }
                }

                
                .recruiter-in-charge {
            
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
            
                    .default-pic-frame {
                        width: 40px;
                        height: 40px;
                        font-size: var(--size-4);
                    }
                }

            }
        }  
    }
}

