.profile-summary-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  height: 100%;
  padding: 24px 15px;

  .profile-summary-action-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
  }

  .add-note-button {
    cursor: pointer;
    display: grid;
    grid-template-columns: 10px 24px auto;
    align-items: center;
    padding: 0;
    width: 82px;
    height: 36px;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    font-size: 16px;
    line-height: 1;

    .text {
      justify-self: left;
    }

    svg {
      display: inline-block;
      width: 17px;
      height: 20px;
    }
  }
}

.note-item {
  border: solid 1px rgba(112, 112, 112, 0.25);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 27px 35px 20px 35px;
  display: grid;
  grid-template-rows: auto auto 28px;

  .icons-container-1, .icons-container-2 {
    display: grid;
    justify-content: end;
    justify-items: end;

  }
  .icons-container-1 {
    grid-template-columns: 165px 37px 27px 27px;
    margin: 0 18px 0 0;
    cursor: pointer;

    .tag-general-conversation {
      cursor: default;
      background-color: #efefef;
    }
    .tag-primary {
      cursor: default;
      background-color: rgba(62, 223, 131, 0.25);
    }

    .note-tag-container {
      padding: 0 10px;
      height: 17px;
      border-radius: 4px;

      text-align: center;

      span {
        position: relative;
        top: -2px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17px;
        letter-spacing: normal;
        text-align: right;
        color: #707070;
      }
    }

    .tack-on {
      svg{
        path {
          stroke: #007dff;
        }
      }
    }
  }
  .summary-note-container {
    grid-template-columns: 27px 27px;
    margin: 0;
  }
  .icons-container-2 {
    grid-template-columns: 165px 26px 26px;
    margin: 0 18px 5px 0;
    .close-icon-container {
      cursor: pointer;
      svg {
        width: 10px;
        height: 10px;

        path {
          fill: #f75245;
        }
      }
    }
    .vee-icon-container {
      cursor: pointer;
      svg {
        position: relative;
        bottom: 1px;
        width: 12px;
        height: 12px;
      }
    }
    .prevent-closing {
      pointer-events: none;
      svg {
        path {
          fill: #c5c5c5;
        }
      }
    }

    .tags-selection-container {
      // display: grid;
      // grid-template-columns: 50px 100px;
      font-size: 12px;
      .modern-select .modern-select-list {
        width: 120px;
      }
    }
  }

  .text-container {
    //direction: rtl;
    font-family: 'ploni';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 22px;

    .public-DraftStyleDefault-block {
      word-break: break-word;
    }

    .job-editor-wrapper .rdw-editor-main {
      border-style: none;
    }

    .job-editor-wrapper.edit-mode .rdw-editor-main {
      border-style: solid;
      border-color: #1a99ff;
    }
  }

  .footer-container {
    display: grid;
    grid-template-columns: 38px auto;
    align-items: center;

    img, .default-pic-frame {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-size: 14px;
    }

    .footer-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #979797;
    }
  }
}
