.inbox-messages-assigment-select {

  .modern-select-list {
    width: 190px;
    left: 2px;
    border-radius: 4px;
    margin-top: 3px;

    .default-pic-frame, img {
      width: 24px;
      height: 24px;
      font-size: 12px;
      margin-left: 4px;
      border-radius: 50%;
    }
  }

  .custom-scroll {
    margin-top: 13px;
    overflow: auto;
    height: 197px;
  }

  .modern-select-list-item {
    padding: 0 0 0 5px;
    margin: 0 0 9px 0;
    display: grid;
    grid-template-columns: auto 1fr;
    color: #000;
    text-align: left;
    grid-gap: 10px;
    align-items: center;
  }

  .modern-select-list-search-input {
    border-radius: 0;
    margin: 3px 0 0 11px;
    width: 153px;
    height: 33px;
  }
}
