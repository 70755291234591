.show-info-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    button {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: var(--color-12);
        margin-right: 8px;
        padding-right: -3px;
        cursor: pointer;

        span {
            margin-left: -1px;
        }

    }

    label {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-18);

        &.mark-label {
            background-color: #d6edff;
        }
    }

    .phone-group {
        display: flex;
        align-items: center;
        .flag-icon {
            margin-left: 8px;
        }
    }
}
