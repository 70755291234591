.doc-test-container {

    .header {
      display: grid;
      grid-template-columns: 180px 340px 160px 300px auto;
      align-items: center;
      .error-message-container {
        color: #ff0000;
        width: 400px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #c5c5c5;
      }

    }


    .body {
        display: grid;
        grid-template-columns: 10% auto;
        width: 100%;

        .profiles-list-container {
          position: relative;
            height: 90vh;
            overflow: auto;
            border: 1px solid black;
            .lds-dual-ring {
              top: 100px;
            }
            .profile-container {
                cursor: pointer;
                &:hover {
                    background-color: #c5c5c5;
                }
            }

            .selected {
              background-color: #c5c5c5;
            }
        }

        .cv-edit-container {
            display: grid;
            grid-template-columns: 800px 800px;
            column-gap: 80px;
            padding: 10px 80px 20px 80px;
            max-width: 800px;
        }
        .trumbowyg-textarea, .trumbowyg-editor {
          background-color: #fff;
        }
        // .bs-cv-edit-container > div {
        //   background-color: #fff;
        //   padding: 60px;
        // }
        .cv-tab {
            padding: 0;
            min-height: 300px;
            display: inline-block;
            width: 100%;
            height: 800px;
            position: relative;
            top: 0;
      
            .cv-wrapper {
              position: relative;
              text-align: center;
              margin-top: 185px;
              display: grid;
              justify-content: center;
      
              button {
                justify-self: center;
                margin-top: 70px;
              }
      
            }
      
            embed, iframe {
              width: 100%;
              height: 950px;
            }
      
            .cv-content {
              div, table {
                width: 100%;
              }
            }
      
            .cv-contacts {
              margin-bottom: 28px;
      
              a {
                color: var(--details-footer-link-color);
              }
            }
      
            .item {
              margin-bottom: 27px;
      
              &.huj {
                margin-top: 30px;
              }
      
              h3, h4, h5 {
                font-size: var(--paragraph-font-size);
              }
      
              h3 {
                color: var(--input-background-color);
                font-weight: 300;
              }
      
              h4 {
                margin-top: 4px;
              }
      
              h5 {
                margin-top: 3px;
              }
      
              p {
                font-size: var(--paragraph-font-size);
                color: var(--paragraph-color-secondary);
              }
      
              ul {
                margin-top: 3px;
      
                li {
                  font-size: var(--paragraph-font-size);
                  color: var(--paragraph-color-secondary);
                  margin-bottom: 5px;
                  line-height: 1.3;
                }
      
                &.highlights, &.sv-height {
                  max-width: 640px;
      
                  li {
                    margin-bottom: 10px;
                    line-height: 1.6;
                  }
                }
      
                &.sv-height {
      
                  li {
                    margin-bottom: 0;
                  }
                }
              }
            }
      
            table {
              color: var(--paragraph-color-secondary);
              margin-top: 5px;
              text-align: left;
              border-spacing: 0;
      
              tr {
                td, th {
                  font-weight: 300;
                  padding: 24px 15px 23px;
                  line-height: 1.4;
                }
      
                &:not(:last-child) td, th {
                  border-bottom: solid 1px var(--link-color);
                }
              }
            }
      
          }
    }
}