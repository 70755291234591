.roles-wrapper {
  position: relative;

  .roles-list {
    //padding: 13px 13px 20px;
    //margin-top: 10px;
  }

  .role-item {
    display: inline-block;
    vertical-align: top;
    margin: 2px;
  }

  .role-button {
    padding: 5px 7px;
    border: solid 1px var(--color-4);
    border-radius: 3px;
    cursor: pointer;

    &.checked {
      background: var(--color-2);
      color: var(--color-8);
      border: solid 1px var(--color-2);
    }

    &.excluded {
      background: var(--color-7);
      color: var(--color-8);
      border: solid 1px var(--color-7);
    }
  }
}
