.overviewContainer {
  padding: 14px 8px 26px;
  position: relative;
  border-radius: 4px;
  background: #fff;

  .sub-title {
    font-size: 18px;
    font-weight: bold;
  }

  .skill-tag {
    font-size: 12px;
    padding: 1px 5px;
    line-height: 1;

    &.mandatory {
      color: var(--color-8);
      background: var(--color-5);
    }
  }

  .edit-item, .message-item {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }

  .message-item {
    right: 63px;

    svg {
      width: 16px;
      height: 12px;

      #a {
          fill: black;
      }

      #b {
          stroke: black;
      }
    }
  }
  
  .reminder-item.candidate {
    position: absolute;
    top: 20px;
    cursor: pointer;
    right: 96px;
  }

  .reminder-item.company {
    position: absolute;
    top: 18px;
    cursor: pointer;
    right: 30px;
  }

  .action-item-more-menu {
    position: absolute;
    // position: relative;
    // display: grid;
    // justify-content: end;
    right: 9px;
    top: 14px;
    // bottom: 4px;
    cursor: pointer;

    .hidden-menu-item {
      cursor: pointer;
      color: var(--color-18);
      font-size: var(--size-314);
    }
  }



  .profile-display {
    text-align: center;
    margin-top: 13px;

    img {
      width: 62px;
      height: 62px;
      border-radius: 50%;
    }

    .default-pic-frame {
      width: 62px;
      height: 62px;
      margin: 0 auto 11px;
      font-size: 36px;
    }
  }

  h2 {
    text-align: center;
    font-size: var(--size-5);
    margin: 4px 0 0;
    line-height: 1;
  }

  h4 {
    text-align: center;
    font-size: var(--size-314);
    color: var(--color-18);
    font-weight: 500;
    margin: 1px 0 4px 0;
  }

  p {
    word-break: break-all;
    margin: auto 0;
    color: var(--color-18);
  }

  legend {
    font-size: var(--size-5);
    color: var(--color-18);
    font-weight: bold;
    margin: 35px 0 10px;
  }

  .matching-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px 0;

    .matching-fields-item {
      padding-left: 6px;

      svg {
        margin-right: 11px;
      }
    }
  }

  .overview-tabs {
    padding: 20px 6px 0 !important;

    .tabs {
      margin: 0;
      display: grid;
      text-align: center;
      height: 19px;
      border-bottom: 1px solid #707070;

      .tab {
        margin: 0;
        width: 100%;
        padding: 0;
        opacity: 1;

        .title {
          font-size: 14px;
          display: block;
          padding: 0;
          font-weight: bold;
        }

        &.selected {
          .title {
            border-bottom: solid 1px var(--color-2);
          }
        }
      }
    }
  }

  .progress-bar {
    width: 52%;
  }

  .background-info {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    align-items: baseline;
    margin: 0 0 17px;
    grid-gap: 2px 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: var(--size-4);
    padding-top: 23px;
    border-top: 1px solid #b5b5b5;

    &.borderless {
      border: none;
    }

    &.contact {
      border: none;
      margin: 0 0 13px;
      padding: 0;
    }

    .background-info-item {
      display: grid;
      grid-template-columns: 1.4fr 2fr;

      p {
        text-transform: capitalize;
      }
    }

    legend {
      font-size: var(--size-314);
      margin: 0;
      font-weight: bold;
    }

    .progress-text {
      font-size: 12px;
    }

    p {
      font-size: var(--size-314);
      word-break: normal;
    }
  }

  .contant-info {
    .top {
      font-size: 12px;
      font-weight: bold;
      margin: 24px 0;
      position: relative;

      svg {
        vertical-align: middle;
        margin-right: 9px;
      }
    }

    .bottom {
      display: block;
    }

    .crosses {
      position: absolute;
      right: -1px;
      top: 4px;
      width: 15px;
      height: 15px;
      cursor: pointer;

      .one,
      .two {
        background: #000;
        width: 1px;
        height: 15px;
        position: absolute;
        left: 50%;
      }

      .one {
        transform: rotate(-270deg);
      }
    }

    &.open {
      .one {
        transform: rotate(-316deg);
      }

      .two {
        transform: rotate(-406deg);
      }

      .top {
        margin: 23px 0 13px;
      }
    }
  }

  .company-info-job,
  .company-info {
    padding: 0 0 29px;
    margin: 13px 7px 24px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 18px;
    align-items: center;
    border-bottom: 1px solid #b5b5b5;

    img,
    .default-pic-frame {
      width: 62px;
      height: 62px;
      font-size: 25px;
      border-radius: inherit;
      margin: 0 auto;
    }

    .info {
      h3 {
        font-weight: bold;
        //color: #000;
        //font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .company-info {
    display: block;
    text-align: center;
    margin: 13px 7px 0;
    padding: 0;

    p {
      text-align: center;
    }

    .info {
      margin: 7px 0 25px 0;
      text-transform: capitalize;
    }

    .default-pic-frame,
    img {
      border: 1px solid #979797;
    }
  }

  &.job {
    .sub-title {
      margin-left: 7px;
    }

    .background-info {
      margin: 0 7px 39px;
      padding: 0;
    }
  }

  &.company {
    .background-info {
      padding: 23px 8px 0;
    }

    .background-info-item {
      margin-bottom: 4px;

      p {
        padding: 0;
      }

      &.org-size {
        margin-bottom: 25px;
      }
    }
  }

  .social-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, 28px);
  }

  .transportations {
    display: grid;
    grid-template-columns: repeat(auto-fit, 30px);
    margin-top: 3px;
  }
}

.overviewWrapper {

  // max-height: 780px;
  overflow-y: auto;

  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: relative;

  .back-icon {
    display: none;
  }

  &.is-loading {
    box-shadow: none;
    height: 100%;
  }

  .company-pop-up-modal {
    z-index: 20;
    background-color: rgba(4, 4, 4, 0.2);
    
    opacity: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .company-pop-up-container {
      position:fixed;
      top: 0; 
      left: 0;
      transform: translate(calc(50vw - 50%), calc(50vh - 80%));
      z-index: 20;
      opacity: 1;
      width: 800px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      padding: 40px;
      display: grid;
      grid-template-rows: auto auto 44px;
  
      .header {
        display: grid;
        grid-template-rows: auto;
        justify-items: center;
        font-family: Ploni DL 1.1 AAA;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 35px;
        
        color: #404040;      
      }
      .body {
        margin: 20px 0;
        max-height: 200px;
        div {
          margin-bottom: 10px;
        }
      }
      .message {
        font-family: Ploni DL 1.1 AAA;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
  
        color: #404040;
      }
      .footer {
        display: grid;
        grid-template-rows: auto;
        justify-items: center;
      }
    }
  }


  .overviewContainer,
  .coordinator {
    min-height: unset;
    height: fit-content;
  }

  .profile-overview {
    h1 {
      text-align: center;
      font-size: var(--size-5);
      margin: auto;
      line-height: auto;
      color: var(--color-18);
    }

    h2 {
      text-align: center;
      font-size: var(--size-314);
      margin: auto;
      line-height: auto;
      font-weight: normal;
      color: var(--color-18);
    }

    h3 {
      text-align: center;
      font-size: var(--size-314);
      color: var(--color-4);
      font-weight: normal;
      margin: 0;
    }

    .invite-talent-status {
      .status-bar {
        justify-content: left;
      }
    }

    .company-name {
      &.with-summary{
        display: grid;
        grid-template-columns: 1fr auto;
        width: fit-content;
        margin: 0 auto;
        align-items: center;
      }
      
      .show-info-wrapper, .summary-more-info {
        justify-content: center;
        align-items: center;
        margin-top: 1px;
        display: grid;
        grid-template-columns: auto auto;

        label {
          font-size: var(--size-314);
          color: var(--color-4);
          font-weight: normal;
          display: inline-block;
          margin-top: 2px;
        }

        a {
          label {
            cursor: pointer;
            color: var(--color-2);
          }
        }
      }
      
      .summary-more-info{
        cursor: pointer;
      }
    }

    .education {
      margin-top: 5px;
    }

    .claimed {
      background-color: #f75245;
      width: max-content;
      border-radius: 3px;
      padding: 0 6px;
      margin: 0 auto;

      label {
        font-size: var(--size-2);
        color: var(--color-8);
        font-weight: bold;
        margin-left: 3px;
      }

      svg {
        height: 14px;
        padding-top: 7px;
      }
    }

    .background-info {
      grid-gap: 5px 17px;
      margin-bottom: 24px;

      &.top1 {
        margin-top: 4px;
        border-top: 1px solid #707070;
        margin-bottom: 14px;
        padding-top: 20px;
      }

      &.hide-border {
        border-top: none;
      }

      .background-info-item {
        font-size: 14px;
        grid-template-columns: 1.35fr 2fr;

        .grid-view {

          display: grid;
          grid-template-columns: 40px auto;

          .status {
            position: relative;
            margin-top: -5px;
            margin-left: 5px;
  
            &:before {
              content: "•";
              font-size:20px;
              color: var(--color-7);
              
            }
    
            &.red {
              &:before {
                color: var(--color-7);
              }
            }
          }
        }

      }

      &.no-margin {
        margin: 0;
      }

      .coordinator {
        margin-top: 0;
        padding: 0 0;

        .default-pic-frame {
          width: 40px;
          height: 40px;
          font-size: var(--size-4);
        }

        &.shadow4 {
          border: none;
          box-shadow: none;
        }

        button {
          background-color: var(--color-18);
        }
      }
    }
  }

  .profile-overview-edit-mode {
    padding: 20px 8px 20px 16px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #666;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
      border-radius: 3px;
    }

    h2 {
      font-size: var(--size-4);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--color-18);
      text-align: left;
    }

    .row {
      display: flex;

      h1 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-18);
      }

      .disbale-profile-image-upload {
        display: none;
      }
      .profile-image-upload {
        display: flex;
        text-align: center;
        margin-top: 0;
        margin-left: 28px;
        position: relative;
      } 

      .profile-display {
        text-align: center;
        margin-top: 0;
        margin-left: 28px;
        position: relative;

        &.disable-profile-image-delete {
          pointer-events: none;
        }

        &:hover  {
          .default-image-component {
            display: none;
          }
          .profile-image-delete {
            display: flex;
          }
        }

        img {
          width: 140px;
          height: 140px;
          border-radius: 50%;
        }

        .default-pic-frame {
          width: 140px;
          height: 140px;
          margin: 0 auto;
          font-size: 36px;
        }

        .default-image-component {          
          width: 140px;
          height: 140px;
          border-radius: 50%;
          border:1px solid black;

          &:hover {
            pointer-events: none;
          }
        }

        .profile-image-delete {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          border:1px solid black;
          display: none;
          align-items: center;
          justify-content: center;
          position: relative;
         

          button {
            background: transparent;
            cursor: pointer;
          }

        }

      }

      .form-item1 {
        margin-right: 15px;
      }
    }

    input {
      margin-top: 7px;
    }

    .input-wrapper {
      margin-top: 24px;
      font-size: 14px;

      &.email,
      &.mobile {
        display: block;

        .form-item {
          margin-top: 12px;
        }

        .add-button {
          width: 62px;
          height: 36px;
          background-color: rgba(155, 155, 155, 0.5);
          border-radius: 3.6px;
          box-shadow: 0 1px 4px 3px rgba(0, 0, 0, 0.02);
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-left: 7px;
        }
      }

      &.email {
        margin-top: 35px;

        .form-item > input {
          margin-left: 4px;
        }
      }



      &.more-details {
        display: grid;
        grid-template-columns: 132px auto;
        align-items: flex-end;

        .input-wrapper {
          position: relative;

          input {
            font-size: 12px;
            padding: 0 7px;
          }
  
          .open-button {
            position: absolute;
            top: 36px;
            right: 12px;
            background: #fff;
            pointer-events: none;
            line-height: unset;
            padding: 0;
            border: none;
          }
        }

        .gender {
          margin-left: 20px;

          .styled-radio-buttons {
            margin-top: 5px;
            grid-template-columns: repeat(auto-fit, 65px);

            button.styled-radio-button {
              border: 1px solid var(--color-10);
              border-right: none;

              &:last-child {
                border-right: 1px solid var(--color-10);
              }
            }
          }
        }
      }

      .role-item {
        font-size: 14px;
      }

      &.linkedin {
        margin-top: 44px;

        svg {
          top: 3px;
          margin-right: 6px;
          position: relative;
        }
      }

      .form-item {
        label {
          font-size: var(--size-4);
        }
      }
    }

    .managemen-roles-only,
    .discrete-search,
    .relocation,
    .security-clearence {
      margin-top: 27px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .fancy-switch-button {
        justify-self: right;
      }
    }

    .skills1,
    .role,
    .field {

      .bp3-tag {
        font-size: 12px;
        padding: 3px 6px;

      }

      input {
        margin-top: 1px;
        margin-bottom: 1px;
      }


    }

    .skills1 {
      margin-top: 30px;
    }

    .suggestions {
      margin-top: 18px;
      display: flex;

      h2 {
        margin: 0;
        font-size: var(--size-314);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-4);
      }

      .suggestions-list {
        position: relative;

        .suggest-item {
          border-radius: 3.5px;
          border: solid 1px #979797;
          font-size: var(--size-314);
          margin-right: 6px;
          padding: 2px 5px;
          cursor: pointer;
        }

        .all {
          display: none;
        }
      }
    }

    .position-type {

      margin-top: 37px;
      border-top: 1px solid var(--color-12);
      padding-top: 31px;


      .position-type-wrapper {

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        label {
          box-sizing: border-box;
          color: var(--color-18);
          font-size: var(--size-314);
          font-weight: 600;
          margin-left: 2px;

          &.customCheckbox-label {
            font-size: var(--size-4);
            font-weight: normal;
            display: block;

            .customCheckbox {
              margin-top: 3px;
              display: inline-block;
              vertical-align: bottom;
              margin-right: 5px;
            }
          }
        }
      }

    }

    .avaliability{
      margin-top: 24px;
      display: grid;
      grid-template-columns: 132px auto;
      align-items: center;

      h2 {
        margin-top: 0;
      }

      .styled-radio-buttons {
        margin-top: 0;
        grid-template-columns: repeat(auto-fit, 72px);
        justify-content: end;

        button.styled-radio-button {
          border: 1px solid var(--color-10);
          border-right: none;

          &:last-child {
            border-right: 1px solid var(--color-10);
          }
        }
      }
    }

    .salary,
    .seniority {

      margin-top: 35px;

      .form-item {
        select {
          margin-top: 8px;
        }
      }

    }

    .salary {
      display: grid;
      grid-template-columns: auto 87px;

      .salary-type-wrapper {
        margin-right: 26px;
      }
    }

    .seniority {

      .number-of-employees {
        margin-top: 14px;

        select {
          width: 172px;
        }

        label {
          font-size: 14px;
          font-weight: normal;
        }
      }

      .managemen-roles-only {
        margin-top: 12px;
      }
    }

    .current {

      .current-wrapper {
        margin-right: 10px;
      }

      .form-item {

        width: 100%;

        select {
          margin-top: 8px;
        }
      }
    }

  }

  .border {
    width: 100%;
    height: 1px;
    background-color: var(--color-12);
    margin-top: 41px;
  }

  .hidden-menu {
    // padding-bottom: 5px;
    width: 200px;

    & > .profile-status {
      display: none;
    }

    .hidden-menu-item {
      // margin-top: 13px;
      // :first-child {
      //   margin-top: 13px;
      // }
      grid-template-columns: 0.15fr 1fr;
    }

    .plus {

      svg {
        margin-left: 2px;   
      }
    }
  
    .share-profile {
      .text {
        margin-top: 2px;
      }
    }
  
    .send-cv {
      svg {
        width: 18px;
        height: 20px;
      }

      .text {
        margin-top: 1px;
      }
    }
  
    .mail {
    
      svg {
        margin-left: 2.5px;
        width: 16px;
        height: 12px;
  
        #Path_41 {
          fill: black;
        }
  
        #Path_4 {
          stroke: black;
        }    
      }
    }
  
    .share-profile{
      svg {
        width: 20px;
        height: 20px;
      }
    }
  
    .invite-as-employer {
      
      margin-top: 0px;
  
      .text {
        margin-top: 7px;
      }
    }
  
    .invite-as-talent {

      .text {
        margin-top: 5px;
      }
    }

    .print-cv {
  
      svg {
        margin-left: 2px;
      }
    }
  }
}

.coordinator {
  // margin-top: 15px;
  // padding: 18px 14px;

  .coordinator-data {
    display: grid;
    grid-template-columns: 1fr 5fr 2.2fr;
    grid-gap: 12px;

    legend {
      color: var(--color-18);
      font-size: var(--size-2);
      margin: 3px 0 3px;
    }

    p {
      color: var(--color-18);
      font-size: var(--size-3);
      font-weight: bold;
    }

    button {
      background: var(--color-14);
      justify-self: center;
      height: fit-content;
      align-self: center;
    }

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.multiselect-wrappr { 
  margin-top: 16px;

  .BS-label {
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }

  .bp4-popover-wrapper .bp4-popover-target .bp4-input.bp4-tag-input {
    padding: 8px;
    gap: 8px;
    align-items: center;
    background-color: transparent;
    border: solid 1px var(--color-16);
    border-radius: 4px;
    min-height: 38px;

    .bp4-multi-select-tag-input-input {
      height: auto;
    }

    .reset-button {
      padding: 0;
      position: static;
    }
  }

  .bp4-tag-input-values {
    gap: 4px;
  }

  .bp4-tag.reusable-listed-tag {
    margin: 0;
    height: 28px;

    .tag-item {
      font-size: 14px;
    }
  }
}
