table {
  border-collapse: collapse;

  &.table-simple {
    width: 100%;

    tbody {

      tr {
        border-top: 1px solid var(--rare-bg-color-border);

        td {
          padding: 10px 0;
        }
      }
    }
  }


  .customCheckbox {
    @media (max-width: 570px) {
      display: none;
    }
  }

  .empty-table {
    min-height: 120px;
    padding-top: 48px;
    font-size: 17px;
  }
}
