.coordinator {

    img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: var(--size-4);
    }
    .default-pic-frame {
        width: 40px;
        height: 40px;
        font-size: var(--size-4);
    }

    .contact-with-email {
        display: flex;
        position: relative;

        .separator {
            font-size: 14px;
            line-height: 1em;
            height: 20px;
            color: black;
            margin-right: 15px;
            margin-left: 15px;
        }

        .email {
            position: relative; 
            font-size: 14px;
            line-height: 1em;     
            text-decoration: underline;
            text-underline-position: under;
        }

        a {
            font-size: 14px;
            white-space: nowrap;
            text-indent: inherit;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

