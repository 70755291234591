:root {
  // COLORS
  --bg-color: #dcdcdc;
  --link-color: #ccc;
  --link-color-selected: #FF7041;
  --text-color: #555;
  --heading-color: #bbb;
  --nav-color: #777;
  --table-hd-bg-color: #111;
  --table-hd-text-color: #aaa;
  --table-sec-hd-bg-color: rgba(127, 132, 135, 0.7);
  --table-sec-hd-text-color: #fff;
  --action-text-color: #46A2F1;
  --action-text-color-selected: #267DC8;
  --paragraph-color: #333;
  --paragraph-color-secondary: #666;
  --rare-background-color: #444;
  --input-background-color: #999;
  --input-background-color-secondary: #f4f4f4;
  --table-heading-bg-color: #9b9fa1;
  --table-heading-bg-color-hover: #777a7c;
  --table-row-bg-color-hover: #2A80CC;
  --icon-color-hover: #7f8487;
  --icon-danger-color: #C83838;
  --icon-ok-color: #48A515;
  --icon-ok-color-secondary: #3AA400;
  --icon-ok-color-third: #46890A;
  --icon-ok-color-hover: #3F8814;
  --rare-bg-color-border: #ddd;
  --select-color-focus: #66afe9;
  --input-invalid-color: #FF0000;
  --button-color: #808080;
  --menu-item-color-hover: #3E96E2;
  --circle-text-color: #eee;
  --strong-emphasis: #000;
  --rare-heading-color: #FF6634;
  --details-footer-link-color: #337ab7;
  --details-footer-link-color-hover: #289CFF;


  --color-1: #0c102f;
  --color-2: #1a99ff;
  --color-3: #f8f8f8;
  --color-4: #707070;
  --color-5: #456bb0;
  --color-7: #f75245;
  --color-8: #ffffff;
  --color-9: #eeeeee;
  --color-10: #979797;
  --color-11: #45f77b;
  --color-12: #d8d8d8;
  --color-13: #cecece;
  --color-14: #1c2152;
  --color-15: rgba(69, 107, 176, 0.98);
  --color-16: rgba(112, 112, 112, 0.25);
  --color-17: rgba(112, 112, 112, 0.5);
  --color-18: #000;
  --color-19: #34c35f;
  --color-20: #dbdbdb;
  --color-21: #02074f;
  --color-22: #b5b5b5;
  --color-23: #9b9b9b;
  --color-24: #ffd800;
  --color-25: #e5e5e5;
  --color-26: #007dff;
  --color-27: #d6edff;
  --color-28: #70707A40;
  --color-29: #404040;
  --color-30: #307FF6;
  --color-31: #34C759;
  --color-32: #002a84;
  --color-33: #6c6c6c;
  --color-34: #890cd1;
  --color-35: #10eeee;
  --color-36: #f309f3;

  // Design system
  --Mid-grey: #6c6c6c;
  --Grey: #A4A4A4;
  --Light-Grey: #C7C7C7;

  // FONTS
  --paragraph-s-font-size: 12px;
  --table-heading-font-size: 13px;
  --body-font-size: 16px;
  --paragraph-font-size: 15px;
  --paragraph-m-font-size: 16px;
  --icon-m-font-size: 20px;
  --size-1: 10px;
  --size-2: 12px;
  --size-3: 15px;
  --size-314: 14px;
  --size-4: 16px;
  --size-5: 18px;
  --size-6: 22px;
  --size-13: 26px;
  --size-7: 28px;
  --size-8: 34px;
  --size-9: 56px;
  --size-10: 36px;
  --size-11: 66px;
  --size-12: 44px;
  --size-13: 60px;
  --size-14: 35px;
  --h4-font-size: 18px;
  --h3-font-size: 22px;
  --h2-font-size: 28px;
  --h1-font-size: 34px;

  --stroke-size: 0;
  --menu-border-radius: 12px;
}

