@font-face {
  font-family: 'ploni';
  font-weight: 400; /*(regular)*/
  font-style: normal;
  src: url('/fonts/ploni-regular-aaa.woff2') format('woff2'),
  url('/fonts/ploni-regular-aaa.woff') format('woff');
}

@font-face {
  font-family: 'ploni';
  font-weight: 700; /*(bold)*/
  font-style: normal;
  src: url('/fonts/ploni-demibold-aaa.woff2') format('woff2'),
  url('/fonts/ploni-demibold-aaa.woff') format('woff');
}
