.dashboard-activities-people-wrapper {

    .title {
        margin-top: 25px;
        margin-left: 25px;    
    }


    .content {

        margin: 49px 68px 0 66px;

        .top1, .bottom {
            height: 100%;
            display: grid;
            grid-template-columns: 90px 72px;
            column-gap: 91px;
            justify-content: center;
        }

        .bottom {
            margin-top: 58px;
        }

    }

    .more-menu {
        position: relative;

        svg {
            position: absolute;
            top: 0;
            right: 25px;
        }
    }

}