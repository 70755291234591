.user-details-message-templates {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 2fr;
  background: #fff;
  padding: 30px;
  margin-top: 30px;

  .user-details-message-templates-list-item-body {
    white-space: pre-line;
    word-break: break-word;
    margin-top: 25px;
  }

  .user-details-message-templates-list-item {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 33px 0 10px;
    
    .user-details-message-templates-list-item-labling{
      margin-bottom: 10px;
      
      label{
        font-weight: 300;
        font-size: 16px;
      }
    }

    h4{
      font-size: 18px;
      margin-bottom: 32px;
    }
    
    .lang{
      font-weight: 600;
      font-size: 16px;
      margin: 5px 0;
    }

    footer{
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: fit-content;
      grid-gap: 15px;
      div{
        cursor: pointer;
      }
    }
  }

  .user-details-message-templates-selector {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: fit-content;
    border: 1px solid #000;
    border-radius: 9px;
    overflow: hidden;
  }

  .user-details-message-templates-selector-item {
    border-right: 1px solid #000;
    cursor: pointer;
    height: 31px;
    width: 45px;
    display: grid;
    justify-content: center;
    align-content: center;

    &:last-child {
      border-right: none;
    }

    &.selected {
      background: #007dff;
      color: #fff;

      svg {
        #Path_4, #Path_41 {
          stroke: #fff
        }

        #Group_614 {
          path, line {
            stroke: #fff
          }
        }
      }
    }
  }

  .user-details-message-templates-list-items-wrap {
    margin: 25px 0;
  }
}

