.form {
  .form-item {
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 26px;
    }

    &.required {

      label {
        position: relative;

        &:after {
          content: '*';
          position: absolute;
          top: -5px;
          font-size: var(--icon-m-font-size);
          font-weight: 600;
        }
      }
    }
    input, select {
      margin-top: 6px;
    }
  }

  .form-item-inline {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 27px;
  }

  .form-counter {
    display: inline-block;
    vertical-align: top;
    float: right;
  }

  .image-uploader {
    margin-bottom: 137px;

    p {
      color: var(--paragraph-color-secondary);
      font-size: var(--paragraph-font-size);
    }
  }

  .form-mandatory {
    position: relative;
    margin-left: 13px;

    &:before {
      content: '*';
      position: absolute;
      top: -8px;
      font-size: var(--icon-m-font-size);
      font-weight: 600;
      left: -13px;
    }
  }

  textarea {
    height: fit-content;
    min-height: 100px;
  }

  textarea:invalid {
    border: 1px solid var(--input-invalid-color);
  }

  label {
    box-sizing: border-box;
    color: var(--color-18);
    font-size: var(--size-314);
    font-weight: 600;
    margin-left: 2px;

    &.customCheckbox-label {
      font-size: var(--size-4);
      font-weight: normal;
      display: block;

      .customCheckbox {
        display: inline-block;
        vertical-align: bottom;
        margin-right: 5px;
      }

    }

    &.with-icon {

      svg {
        margin: 0 8px 0 1px;
        position: relative;
        top: 3px;
      }
    }
  }

  .grid-2-1 {
    display: grid;
    grid-template-columns: 65.7% auto;
    grid-gap: 4px 18px;
  }

  .grid-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4px 18px;
    align-items: end;
  }
}
