@import '../../../../styles/mixins.scss';

.company-edit-wrapper {
  margin-top: 179px;

  .main-container {
    width: 794px;
    margin: auto;
  }

  .form label {
    font-size: 16px;
  }

  .main-details, .secondary-details, .more-info {
    margin-bottom: 16px;
    padding: 21px 0 16px;
  }

  .company-id {
    margin-top: 6px;
    border-radius: 4px;
    background: #ffffff;
    background: var(--color-8);
    border: solid 1px rgba(112, 112, 112, 0.25);
    border: solid 1px var(--color-16);
    height: 36px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding-left: 5px;
  }

  h1 {
    font-size: var(--size-10);
    margin-bottom: 15px;
  }

  .logo {
    font-size: var(--size-314);
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 2px 16px 0;
    grid-gap: 29px;
  }

  .logo-image {
    height: 120px;

    svg, img {
      width: 120px;
      height: 120px;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
      cursor: pointer;
    }
  }

  .item {
    padding: 0 21px 0 19px;
    .phone-input-field{
      .reusable-input-container {
        padding: unset !important;
      }
  }

  }

  .space {
    margin-bottom: 60px;
  }

  .space2 {
    margin-bottom: 22px;

    .autocomplete {
      margin-top: 8px;

      .MuiOutlinedInput-root {
        border-radius: 4px;
        background-color: #fff;
      }
      
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--color-16)
      }
    }
  }

  .grid3x1 {
    display: grid;
    grid-template-columns: auto 31.9%;
    grid-gap: 17px;
  }

  .grid1x1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }

  .grid1x3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 17px;
  }


  .grid2x1 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 12px;
  }

  h3 {
    margin-bottom: 17px;
  }

  .margin-bottom {
    margin-bottom: 21px;
  }

  .form .form-item3 {
    margin-bottom: 50px;
  }

  hr {
    padding: 0 10px;
    width: 92.6%;
    margin: 17px auto 37px;
  }

  .branches-locations {


    display: grid;

    .entry {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 17px;
      margin-bottom: 9px;

      .form-item {
        margin: 0;
      }
    }

    .button {
      width: 100%;
      height: fit-content;
      align-self: end;
      font-size: var(--size-3);
      font-weight: bold;

      &.more-cities {
        margin-top: 22px;
        width: 175.5px;
        justify-self: end;
      }
    }

    .branches-list {
      border: 1px solid rgba(112, 112, 112, 0.3);
      margin-top: 20px;
    }

    .branch {
      border-bottom: 1px solid rgba(112, 112, 112, 0.3);
      display: grid;
      grid-template-columns: 1fr auto;
      padding: 16px 17px 4px 36px;
      min-height: 88px;
      position: relative;

      p {
        margin-top: 5px;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .error-message {
    // position: absolute;
    // right: 0;
    // top: -34px;
    text-align: right;
    color: var(--color-7);
  }

  textarea {
    min-height: 159px;
  }

  footer {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: right;
    grid-gap: 18px;
    margin-top: 50px;

    .button {
      width: 221px;
      font-weight: bold;
      font-size: var(--size-6) !important;
    }

    a:hover {
      color: var(--color-8);
    }
  }

  .logoPreview {

    &.invalid {
      border: 1px solid var(--color-7);
    }
  }

  .error-logo {
    color: var(--color-7);
  }

  .benefit-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 10px;
    margin-bottom: 36px;
  }

  .contact-info {
    margin: 2px 0 33px 19px;
  }

  .more-sites {
    margin-top: 15px;
    margin-bottom: 31px;
  }

  .from-the-web {
    .entry {
      grid-template-columns: 91% auto;
      grid-gap: 8px;
    }

    .branch {
      min-height: 50px;
    }
  }

  .promo-video {
    margin-top: 40px;
  }

  .customCheckbox-label {
    cursor: pointer;
  }

  .remove-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 36%;
  }

  @include mobile {
    padding: 16px;
    margin-top: 0;

    .main-container {
      width: 100%;
    }

    .main-details {
      padding: 20px;
      margin-bottom: 0;
      border-radius: 12px;

      & > * { padding: 0 }
    }

    .logo.space {
      display: flex;
      justify-content: space-between;
    }

    .form label {
      font-size: 14px;
    }

    .grid1x1 {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
    }

    .grid1x3 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0;
      margin-bottom: 0;

      & > * {
        width: calc(50% - 8px);
      }

      & > *:last-child {
        width: 100%;
      }
    }

    input, select, .bp3-tag > .bp3-fill {
      font-size: 14px;
      padding: 0 8px;
    }

    .benefit-items {
      grid-template-columns: 1fr 1fr;
    }

    .contact-info {
      margin-left: 0;
      margin-bottom: 16px;
    }

    .branches-locations .entry {
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }

    .branches-locations.from-the-web .entry {
      grid-template-columns: 1fr 80px;
    }

    .branches-locations .branch {
      min-height: 0;
      padding: 16px 20px;
      display: flex;
      align-items: center;
    }

    .branches-locations .button.more-cities {
      width: auto;
      min-width: 80px;
    }

    .bp3-tag-input .bp3-tag-input-values {
      gap: 4px;
    }

    textarea {
      padding: 8px 16px;
      font-size: 14px;
    }

    hr {
      display: none;
    }
  }
}

.edit-company-top {
  position: fixed;
  width: 100%;
  top: 92px;
  z-index: 1;

  .gazmanov-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 64px;
    align-items: center;
  }

  .left {
    font-size: 28px;
    font-weight: bold;
    line-height: 2.6;
  }

  .right {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;
    grid-gap: 20px;
  }

  .button {
    width: 153px;
    height: 38px;
  }

  .bk-less {
    line-height: 1.8;
  }

  @include mobile {
    position: static;
    margin-top: 92px;
    margin-bottom: 0;
    border: none;
    padding: 16px;

    .gazmanov-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      height: auto;
    }

    .left {
      font-size: 20px;
      line-height: normal;
      flex: 1;
    }

    .right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      & > * {
        width: auto;
        height: auto;
        padding: 4px;
      }
    }
  }
}
