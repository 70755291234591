.dashboard-activities-conversations-wrapper {

    .title {
        margin-top: 25px;
        margin-left: 25px;
    }

    .content {
        margin-top: 23px;
        position: relative;

        .lds-dual-ring {
            transform: translateY(250%);
        }

        .item {
            height: 62px;
            border-bottom: 1px solid var(--color-9);

            .inner-item {
                display: grid;
                grid-template-columns: 55px auto max-content;
                align-items: center;
                margin: 0 25px;
                height: 100%;

                &.mail {
                    .type, .number {
                        color: var(--color-26);
                        font-weight: bold;
                    }
                }

                .type {
                    font-size: var(--size-4);
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1em;
                    letter-spacing: normal;
                    color: var(--color-18);
                    margin-top: 2px;
                }

                .number {
                    font-size: var(--size-6);
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1em;
                    letter-spacing: normal;
                    color: var(--color-18);
                    cursor: pointer;

                    &.none {
                        color: var(--color-10);
                    }

                    &.mail {
                        font-weight: bold;
                        color: var(--color-26);
                    }
                }

                .icon-wrapper  {

                    width: 20.5px;
                    display: grid;
                    justify-content: center;

                    &.mail {

                        .type {
                            color: var(--color-18);
                        }

                        svg {
                            width: 20px;
                            height: 14px;
                        }
                    }
    
                    &.sms {
    
                        svg {
                            width: 13px;
                            height: 20px;
                        }
                    }
    
                    &.whatsapp {
    
                        svg {
                            width: 18px;
                            height: 17px;
                        }
                    }
    
                    &.chat {
    
                        svg {
                            width: 20px;
                            height: 18px;
                        }
                    }
    
                    &.discussion {
    
                        svg {
                            width: 19px;
                            height: 15px;
                        }
                    }
                }
    
            }

        }

        & > :first-child {
            height: auto;

            & .inner-item:first-child {
                padding-top: 24px;
                margin-bottom: 24px;
            }
        }

        
        & :last-child {
            border-bottom: none;
        }

    }

    .more-menu {
        position: relative;

        svg {
            position: absolute;
            top: 0;
            right: 25px;
        }
    }

}