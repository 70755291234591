.drop-down-button {
    margin-top: 5px;
  
    .main-button {
  
        display: grid;
        grid-template-columns: 100px 20px;
        grid-gap: 1px;
        align-items: center;

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        .send-button {
          display: grid;
          cursor: pointer;
          border-radius: 4px;
          outline: 0;
          background: var(--color-18);
          background-color: #02074f;
          font-size: #fff;
          grid-template-columns: 40px max-content;
          align-items: center;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 5px;
          width: 100px;
          padding-left: 0;
          margin: 15px 0;
          height: 40px;
          color: #fff;
          justify-content: center;
        }
        .selector {
          cursor: pointer;
          background-color: #02074f;
          font-size: #fff;
          border-radius: 4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          color: #fff;
          min-width: 0;
          width: 20px;
          height: 40px;
        }
  
      position: relative;
    }
  
    .selector {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      position: relative;
  
      &:after, &:before {
        border-right: 2px solid;
        content: '';
        display: block;
        height: 8px;
        margin-top: -2px;
        position: absolute;
        transform: rotate(315deg);
        left: 7px;
        top: 50%;
        width: 0;
      }
  
      &:after {
        margin-top: -2px;
        transform: rotate(-315deg);
        left: 11px;
      }
    }
  
    .button {
      font-weight: bold;
      height: 37px;
  
      &.bk-less {
        path {
          fill: var(--color-21);
        }
      }
  
      svg {
        vertical-align: middle;
        margin-right: 6px;
  
        path {
          fill: var(--color-8);
        }
      }
    }
  
    .selector-data {
      width: 100%;
      right: inherit;
      top: 58px;
      left: 0;
      padding-bottom: 6px;
  
      .hidden-menu-item {
        font-size: var(--size-314);
        color: var(--color-18);
        text-align: left;
        padding: 6px 0 2px 6px;
        line-height: 1;
        cursor: pointer;
        margin: 5px 0px 0px 5px;
  
        svg {
          vertical-align: middle;
          margin-right: 14px;
        }
      }
    }
  }
  