.quick-details {
  background: var(--color-3);
  height: auto;
  color: var(--paragraph-color-secondary);
  margin-top: 0;
  text-align: center;
  padding: 91px 7% 0;

  .image-cropper {
    margin: auto;
  }

  h3 {
    margin: 9px 0 1px;
    font-size: var(--size-3);
    color: var(--color-18);
  }

  p, legend {
    text-align: center;
    display: block;
    line-height: 1.2;
    font-size: var(--size-3);
    color: var(--color-4);
  }

  legend {
    margin: 30px 0 5px;
    font-size: var(--size-2);
  }

  .location {
    padding-left: 12px;

    svg {
      position: relative;
      left: -7px;
    }
  }

  .current, .past, .edu {
    margin: 6px 0 6px;
  }

  .fit {
    width: 31%;
    margin: 10px auto;

    .progress-bar {

      .progress-bar-progress {
        background: var(--color-5);
      }
    }

    .fit-data {
      font-size: var(--size-6);
      font-weight: bold;

      span {
        font-size: var(--size-4);
      }
    }
  }
}
